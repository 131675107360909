'use strict';

module.exports = thematicBreak;
var C_NEWLINE = '\n';
var C_TAB = '\t';
var C_SPACE = ' ';
var C_ASTERISK = '*';
var C_UNDERSCORE = '_';
var C_DASH = '-';
var THEMATIC_BREAK_MARKER_COUNT = 3;

function thematicBreak(eat, value, silent) {
  var index = -1;
  var length = value.length + 1;
  var subvalue = '';
  var character;
  var marker;
  var markerCount;
  var queue;

  while (++index < length) {
    character = value.charAt(index);

    if (character !== C_TAB && character !== C_SPACE) {
      break;
    }

    subvalue += character;
  }

  if (character !== C_ASTERISK && character !== C_DASH && character !== C_UNDERSCORE) {
    return;
  }

  marker = character;
  subvalue += character;
  markerCount = 1;
  queue = '';

  while (++index < length) {
    character = value.charAt(index);

    if (character === marker) {
      markerCount++;
      subvalue += queue + marker;
      queue = '';
    } else if (character === C_SPACE) {
      queue += character;
    } else if (markerCount >= THEMATIC_BREAK_MARKER_COUNT && (!character || character === C_NEWLINE)) {
      subvalue += queue;

      if (silent) {
        return true;
      }

      return eat(subvalue)({
        type: 'thematicBreak'
      });
    } else {
      return;
    }
  }
}