import { PERCENT, SCIENTIFIC, NUMBER_PLACEHOLDER, CURRENCY_PLACEHOLDER, PERCENT_PLACEHOLDER, EMPTY, POINT } from '../common/constants';
import formatCurrencySymbol from './format-currency-symbol';
import groupInteger from './group-integer';
import isCurrencyStyle from './is-currency-style';
import pad from '../common/pad';
import round from '../common/round';
import { currencyFractionOptions } from '../cldr';
var DEFAULT_DECIMAL_ROUNDING = 3;
var DEFAULT_PERCENT_ROUNDING = 0;
var trailingZeroRegex = /0+$/;

function fractionOptions(options) {
  var minimumFractionDigits = options.minimumFractionDigits;
  var maximumFractionDigits = options.maximumFractionDigits;
  var style = options.style;
  var isCurrency = isCurrencyStyle(style);
  var currencyFractions;

  if (isCurrency) {
    currencyFractions = currencyFractionOptions(options.currency);
  }

  if (minimumFractionDigits === undefined) {
    minimumFractionDigits = isCurrency ? currencyFractions.minimumFractionDigits : 0;
  }

  if (maximumFractionDigits === undefined) {
    if (style === PERCENT) {
      maximumFractionDigits = Math.max(minimumFractionDigits, DEFAULT_PERCENT_ROUNDING);
    } else if (isCurrency) {
      maximumFractionDigits = Math.max(minimumFractionDigits, currencyFractions.maximumFractionDigits);
    } else {
      maximumFractionDigits = Math.max(minimumFractionDigits, DEFAULT_DECIMAL_ROUNDING);
    }
  }

  return {
    minimumFractionDigits: minimumFractionDigits,
    maximumFractionDigits: maximumFractionDigits
  };
}

function applyPattern(value, pattern, symbol) {
  var result = EMPTY;

  for (var idx = 0, length = pattern.length; idx < length; idx++) {
    var ch = pattern.charAt(idx);

    if (ch === NUMBER_PLACEHOLDER) {
      result += value;
    } else if (ch === CURRENCY_PLACEHOLDER || ch === PERCENT_PLACEHOLDER) {
      result += symbol;
    } else {
      result += ch;
    }
  }

  return result;
}

function currencyUnitPattern(info, value) {
  var currencyInfo = info.numbers.currency;
  var pattern = value !== 1 ? currencyInfo["unitPattern-count-other"] : currencyInfo["unitPattern-count-one"];

  if (value < 0) {
    pattern = pattern.replace(NUMBER_PLACEHOLDER, "-" + NUMBER_PLACEHOLDER);
  }

  return pattern;
}

export default function standardNumberFormat(number, options, info) {
  var symbols = info.numbers.symbols;
  var style = options.style;
  var isCurrency = isCurrencyStyle(style); //return number in exponential format

  if (style === SCIENTIFIC) {
    var exponential = options.minimumFractionDigits !== undefined ? number.toExponential(options.minimumFractionDigits) : number.toExponential();
    return exponential.replace(POINT, symbols.decimal);
  }

  var value = number;
  var symbol;

  if (isCurrency) {
    options.value = value;
    symbol = formatCurrencySymbol(info, options);
  }

  if (style === PERCENT) {
    value *= 100;
    symbol = symbols.percentSign;
  }

  var ref = fractionOptions(options);
  var minimumFractionDigits = ref.minimumFractionDigits;
  var maximumFractionDigits = ref.maximumFractionDigits;
  value = round(value, maximumFractionDigits);
  var negative = value < 0;
  var parts = value.split(POINT);
  var integer = parts[0];
  var fraction = pad(parts[1] ? parts[1].replace(trailingZeroRegex, EMPTY) : EMPTY, minimumFractionDigits, true); //exclude "-" if number is negative.

  if (negative) {
    integer = integer.substring(1);
  }

  if (options.minimumIntegerDigits) {
    integer = pad(integer, options.minimumIntegerDigits);
  }

  var formattedValue = options.useGrouping !== false ? groupInteger(integer, 0, integer.length, options, info) : integer;

  if (fraction) {
    formattedValue += symbols.decimal + fraction;
  }

  var pattern;

  if (isCurrency && options.currencyDisplay === "name") {
    pattern = currencyUnitPattern(info, number);
  } else {
    var patterns = options.patterns;
    pattern = negative ? patterns[1] || "-" + patterns[0] : patterns[0];
  }

  if (pattern === NUMBER_PLACEHOLDER && !negative) {
    return formattedValue;
  }

  var result = applyPattern(formattedValue, pattern, symbol);
  return result;
}