import { shiftWeekNames } from '../../utils';
/**
 * @hidden
 */

var WeekNamesService =
/** @class */
function () {
  function WeekNamesService(intl) {
    this.intl = intl;
  }

  WeekNamesService.prototype.getWeekNames = function (includeWeekNumber) {
    if (includeWeekNumber === void 0) {
      includeWeekNumber = false;
    }

    var weekNames = shiftWeekNames(this.intl.dateFormatNames({
      nameType: 'short',
      type: 'days'
    }), this.intl.firstDay());
    return includeWeekNumber ? [''].concat(weekNames) : weekNames;
  };

  return WeekNamesService;
}();

export { WeekNamesService };