import { getNamedFormat, filterProps, createError } from '../utils';
var NUMBER_FORMAT_OPTIONS = ['localeMatcher', 'style', 'currency', 'currencyDisplay', 'unit', 'unitDisplay', 'useGrouping', 'minimumIntegerDigits', 'minimumFractionDigits', 'maximumFractionDigits', 'minimumSignificantDigits', 'maximumSignificantDigits'];
export function getFormatter(_ref, getNumberFormat) {
  var locale = _ref.locale,
      formats = _ref.formats,
      onError = _ref.onError;
  var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  var format = options.format;
  var defaults = format && getNamedFormat(formats, 'number', format, onError) || {};
  var filteredOptions = filterProps(options, NUMBER_FORMAT_OPTIONS, defaults);
  return getNumberFormat(locale, filteredOptions);
}
export function formatNumber(config, getNumberFormat, value) {
  var options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};

  try {
    return getFormatter(config, getNumberFormat, options).format(value);
  } catch (e) {
    config.onError(createError('Error formatting number.', e));
  }

  return String(value);
}
export function formatNumberToParts(config, getNumberFormat, value) {
  var options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};

  try {
    return getFormatter(config, getNumberFormat, options).formatToParts(value);
  } catch (e) {
    config.onError(createError('Error formatting number.', e));
  }

  return [];
}