var rectOfHiddenElement = function rectOfHiddenElement(element) {
  var ref = element.style;
  var display = ref.display;
  var left = ref.left;
  var position = ref.position;
  element.style.display = '';
  element.style.left = '-10000px';
  element.style.position = 'absolute';
  var rect = element.getBoundingClientRect();
  element.style.display = display;
  element.style.left = left;
  element.style.position = position;
  return rect;
};

var offset = function offset(element) {
  var rect = element.getBoundingClientRect();
  var left = rect.left;
  var top = rect.top;

  if (!rect.height && !rect.width) {
    rect = rectOfHiddenElement(element);
  }

  return {
    top: top,
    left: left,
    height: rect.height,
    width: rect.width
  };
};

export default offset;