var _a;

import { addDays, addWeeks, addMonths, dayOfWeek, durationInMonths, getDate, firstDayOfMonth, lastDayOfMonth } from '@progress/kendo-date-math';
import { Action } from '../models/NavigationAction';
import { EMPTY_SELECTIONRANGE } from '../models/SelectionRange';
import { getToday, isInRange, isInSelectionRange, range } from '../../utils';
var EMPTY_DATA = [[]];
var CELLS_LENGTH = 7;
var ROWS_LENGTH = 6;
var SATURDAY = 6;
var SUNDAY = 0;
var ACTIONS = (_a = {}, _a[Action.Left] = function (date) {
  return addDays(date, -1);
}, _a[Action.Up] = function (date) {
  return addWeeks(date, -1);
}, _a[Action.Right] = function (date) {
  return addDays(date, 1);
}, _a[Action.Down] = function (date) {
  return addWeeks(date, 1);
}, _a[Action.PrevView] = function (date) {
  return addMonths(date, -1);
}, _a[Action.NextView] = function (date) {
  return addMonths(date, 1);
}, _a[Action.FirstInView] = function (date) {
  return firstDayOfMonth(date);
}, _a[Action.LastInView] = function (date) {
  return lastDayOfMonth(date);
}, _a);
/**
 * @hidden
 */

var MonthViewService =
/** @class */
function () {
  function MonthViewService(intl) {
    this.intl = intl;
  }

  MonthViewService.prototype.addToDate = function (min, skip) {
    return addMonths(min, skip);
  };

  MonthViewService.prototype.datesList = function (start, count) {
    return range(0, count).map(function (i) {
      return addMonths(start, i);
    });
  };

  MonthViewService.prototype.data = function (options) {
    var _this = this;

    var cellUID = options.cellUID,
        focusedDate = options.focusedDate,
        isActiveView = options.isActiveView,
        max = options.max,
        min = options.min,
        selectedDate = options.selectedDate,
        _a = options.selectionRange,
        selectionRange = _a === void 0 ? EMPTY_SELECTIONRANGE : _a,
        viewDate = options.viewDate;

    if (!viewDate) {
      return EMPTY_DATA;
    }

    var firstMonthDate = firstDayOfMonth(viewDate);
    var lastMonthDate = lastDayOfMonth(viewDate);
    var backward = -1;
    var date = dayOfWeek(firstMonthDate, this.intl.firstDay(), backward);
    var cells = range(0, CELLS_LENGTH);
    var today = getToday();
    return range(0, ROWS_LENGTH).map(function (rowOffset) {
      var baseDate = addDays(date, rowOffset * CELLS_LENGTH);
      return cells.map(function (cellOffset) {
        var cellDate = _this.normalize(addDays(baseDate, cellOffset), min, max);

        var otherMonth = cellDate < firstMonthDate || cellDate > lastMonthDate;
        var outOfRange = cellDate < min || cellDate > max;

        if (otherMonth || outOfRange) {
          return null;
        }

        var isRangeStart = _this.isEqual(cellDate, selectionRange.start);

        var isRangeEnd = _this.isEqual(cellDate, selectionRange.end);

        var isInMiddle = !isRangeStart && !isRangeEnd;
        var isRangeMid = isInMiddle && isInSelectionRange(cellDate, selectionRange);
        var isSelected = isActiveView && (!Array.isArray(selectedDate) ? isInRange(selectedDate, min, max) && _this.isEqual(cellDate, selectedDate) : _this.isSelectedFromArray(cellDate, selectedDate, min, max));
        return {
          formattedValue: _this.value(cellDate),
          id: "" + cellUID + cellDate.getTime(),
          isFocused: _this.isEqual(cellDate, focusedDate),
          isSelected: isSelected,
          isWeekend: _this.isWeekend(cellDate),
          isRangeStart: isRangeStart,
          isRangeMid: isRangeMid,
          isRangeEnd: isRangeEnd,
          isRangeSplitStart: isRangeMid && _this.isEqual(cellDate, firstMonthDate),
          isRangeSplitEnd: isRangeMid && _this.isEqual(cellDate, lastMonthDate),
          isToday: _this.isEqual(cellDate, today),
          title: _this.cellTitle(cellDate),
          value: cellDate
        };
      });
    });
  };

  MonthViewService.prototype.isEqual = function (candidate, expected) {
    if (!candidate || !expected) {
      return false;
    }

    return getDate(candidate).getTime() === getDate(expected).getTime();
  };

  MonthViewService.prototype.isSelectedFromArray = function (candidate, dates, min, max) {
    var _this = this;

    var result = false;
    dates.forEach(function (date) {
      if (isInRange(candidate, min, max) && _this.isEqual(candidate, date)) {
        result = true;
      }
    });
    return result;
  };

  MonthViewService.prototype.isInArray = function (date, dates) {
    return !!dates.length && firstDayOfMonth(dates[0]) <= date && date <= lastDayOfMonth(dates[dates.length - 1]);
  };

  MonthViewService.prototype.isInRange = function (candidate, min, max) {
    var value = getDate(candidate);
    var aboveMin = !min || getDate(min) <= value;
    var belowMax = !max || value <= getDate(max);
    return aboveMin && belowMax;
  };

  MonthViewService.prototype.isInSameView = function (candidate, value) {
    return durationInMonths(candidate, value) === 0;
  };

  MonthViewService.prototype.isRangeStart = function (value) {
    return !value.getMonth();
  };

  MonthViewService.prototype.move = function (value, action) {
    var modifier = ACTIONS[action];

    if (!modifier) {
      return value;
    }

    return modifier(value);
  };

  MonthViewService.prototype.cellTitle = function (value) {
    return this.intl.formatDate(value, 'D');
  };

  MonthViewService.prototype.navigationTitle = function (value) {
    if (!value) {
      return '';
    }

    return this.isRangeStart(value) ? value.getFullYear().toString() : this.abbrMonthNames()[value.getMonth()];
  };

  MonthViewService.prototype.title = function (current) {
    return this.wideMonthNames()[current.getMonth()] + " " + current.getFullYear();
  };

  MonthViewService.prototype.rowLength = function (prependCell) {
    return CELLS_LENGTH + (prependCell ? 1 : 0);
  };

  MonthViewService.prototype.skip = function (value, min) {
    return durationInMonths(min, value);
  };

  MonthViewService.prototype.total = function (min, max) {
    return durationInMonths(min, max) + 1;
  };

  MonthViewService.prototype.value = function (current) {
    return current ? current.getDate().toString() : '';
  };

  MonthViewService.prototype.viewDate = function (date, max, border) {
    if (border === void 0) {
      border = 1;
    }

    var renderTwoViews = durationInMonths(date, max) < border;
    return renderTwoViews ? addMonths(date, -1) : date;
  };

  MonthViewService.prototype.isWeekend = function (date) {
    var day = date.getDay();
    return day === SATURDAY || day === SUNDAY;
  };

  MonthViewService.prototype.abbrMonthNames = function () {
    return this.intl.dateFormatNames({
      nameType: 'abbreviated',
      type: 'months'
    });
  };

  MonthViewService.prototype.normalize = function (cellDate, min, max) {
    if (cellDate < min && this.isEqual(cellDate, min)) {
      return getDate(min);
    }

    if (cellDate > max && this.isEqual(cellDate, max)) {
      return getDate(max);
    }

    return cellDate;
  };

  MonthViewService.prototype.wideMonthNames = function () {
    return this.intl.dateFormatNames({
      nameType: 'wide',
      type: 'months'
    });
  };

  return MonthViewService;
}();

export { MonthViewService };