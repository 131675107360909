var _a;

import { addDecades, addYears, cloneDate, durationInDecades, firstYearOfDecade, lastYearOfDecade } from '@progress/kendo-date-math';
import { Action } from '../models/NavigationAction';
import { EMPTY_SELECTIONRANGE } from '../models/SelectionRange';
import { getToday, isInRange, isInSelectionRange, range } from '../../utils';
var EMPTY_DATA = [[]];
var CELLS_LENGTH = 5;
var ROWS_LENGTH = 2;
var ACTIONS = (_a = {}, _a[Action.Left] = function (date) {
  return addYears(date, -1);
}, _a[Action.Up] = function (date) {
  return addYears(date, -5);
}, _a[Action.Right] = function (date) {
  return addYears(date, 1);
}, _a[Action.Down] = function (date) {
  return addYears(date, 5);
}, _a[Action.PrevView] = function (date) {
  return addDecades(date, -1);
}, _a[Action.NextView] = function (date) {
  return addDecades(date, 1);
}, _a[Action.FirstInView] = function (date) {
  return firstYearOfDecade(date);
}, _a[Action.LastInView] = function (date) {
  return lastYearOfDecade(date);
}, _a);
/**
 * @hidden
 */

var DecadeViewService =
/** @class */
function () {
  function DecadeViewService() {}

  DecadeViewService.prototype.addToDate = function (min, skip) {
    return addDecades(min, skip);
  };

  DecadeViewService.prototype.datesList = function (start, count) {
    return range(0, count).map(function (i) {
      return addDecades(start, i);
    });
  };

  DecadeViewService.prototype.data = function (options) {
    var _this = this;

    var cellUID = options.cellUID,
        focusedDate = options.focusedDate,
        isActiveView = options.isActiveView,
        max = options.max,
        min = options.min,
        selectedDate = options.selectedDate,
        _a = options.selectionRange,
        selectionRange = _a === void 0 ? EMPTY_SELECTIONRANGE : _a,
        viewDate = options.viewDate;

    if (!viewDate) {
      return EMPTY_DATA;
    }

    var cells = range(0, CELLS_LENGTH);
    var firstDate = firstYearOfDecade(viewDate);
    var lastDate = lastYearOfDecade(viewDate);
    var today = getToday();
    return range(0, ROWS_LENGTH).map(function (rowOffset) {
      var baseDate = addYears(firstDate, rowOffset * CELLS_LENGTH);
      return cells.map(function (cellOffset) {
        var cellDate = _this.normalize(addYears(baseDate, cellOffset), min, max);

        if (!_this.isInRange(cellDate, min, max)) {
          return null;
        }

        var isRangeStart = _this.isEqual(cellDate, selectionRange.start);

        var isRangeEnd = _this.isEqual(cellDate, selectionRange.end);

        var isInMiddle = !isRangeStart && !isRangeEnd;
        var isRangeMid = isInMiddle && isInSelectionRange(cellDate, selectionRange);
        var isSelected = isActiveView && (!Array.isArray(selectedDate) ? isInRange(selectedDate, min, max) && _this.isEqual(cellDate, selectedDate) : _this.isSelectedFromArray(cellDate, selectedDate, min, max));
        return {
          formattedValue: _this.value(cellDate),
          id: "" + cellUID + cellDate.getTime(),
          isFocused: _this.isEqual(cellDate, focusedDate),
          isSelected: isSelected,
          isWeekend: false,
          isRangeStart: isRangeStart,
          isRangeMid: isRangeMid,
          isRangeEnd: isRangeEnd,
          isRangeSplitEnd: isRangeMid && _this.isEqual(cellDate, lastDate),
          isRangeSplitStart: isRangeMid && _this.isEqual(cellDate, firstDate),
          isToday: _this.isEqual(cellDate, today),
          title: _this.cellTitle(cellDate),
          value: cellDate
        };
      });
    });
  };

  DecadeViewService.prototype.isSelectedFromArray = function (candidate, dates, min, max) {
    var _this = this;

    var result = false;
    dates.forEach(function (date) {
      if (isInRange(candidate, min, max) && _this.isEqual(candidate, date)) {
        result = true;
      }
    });
    return result;
  };

  DecadeViewService.prototype.isEqual = function (candidate, expected) {
    if (!candidate || !expected) {
      return false;
    }

    return candidate.getFullYear() === expected.getFullYear();
  };

  DecadeViewService.prototype.isInArray = function (date, dates) {
    if (!dates.length) {
      return false;
    }

    var year = date.getFullYear();
    return dates[0].getFullYear() <= year && year <= dates[dates.length - 1].getFullYear() + 9;
  };

  DecadeViewService.prototype.isInRange = function (candidate, min, max) {
    var year = candidate.getFullYear();
    var aboveMin = !min || min.getFullYear() <= year;
    var belowMax = !max || year <= max.getFullYear();
    return aboveMin && belowMax;
  };

  DecadeViewService.prototype.isRangeStart = function (value) {
    return value.getFullYear() % 100 === 0;
  };

  DecadeViewService.prototype.isInSameView = function (candidate, value) {
    return durationInDecades(candidate, value) === 0;
  };

  DecadeViewService.prototype.move = function (value, action) {
    var modifier = ACTIONS[action];

    if (!modifier) {
      return value;
    }

    return modifier(value);
  };

  DecadeViewService.prototype.cellTitle = function (value) {
    return value.getFullYear().toString();
  };

  DecadeViewService.prototype.navigationTitle = function (value) {
    return value ? firstYearOfDecade(value).getFullYear().toString() : '';
  };

  DecadeViewService.prototype.title = function (value) {
    if (!value) {
      return '';
    }

    return firstYearOfDecade(value).getFullYear() + " - " + lastYearOfDecade(value).getFullYear();
  };

  DecadeViewService.prototype.rowLength = function (_) {
    return CELLS_LENGTH;
  };

  DecadeViewService.prototype.skip = function (value, min) {
    return durationInDecades(min, value);
  };

  DecadeViewService.prototype.total = function (min, max) {
    return durationInDecades(min, max) + 1;
  };

  DecadeViewService.prototype.value = function (current) {
    return current ? current.getFullYear().toString() : '';
  };

  DecadeViewService.prototype.viewDate = function (date, max, border) {
    if (border === void 0) {
      border = 1;
    }

    var renderTwoViews = durationInDecades(date, max) < border;
    return renderTwoViews ? addDecades(date, -1) : date;
  };

  DecadeViewService.prototype.normalize = function (cellDate, min, max) {
    if (cellDate < min && this.isEqual(cellDate, min)) {
      return cloneDate(min);
    }

    if (cellDate > max && this.isEqual(cellDate, max)) {
      return cloneDate(max);
    }

    return cellDate;
  };

  return DecadeViewService;
}();

export { DecadeViewService };