var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

import * as React from 'react';
import * as PropTypes from 'prop-types';

var DropDownButtonItem =
/** @class */
function (_super) {
  __extends(DropDownButtonItem, _super);

  function DropDownButtonItem() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  /**
   * @hidden
   */


  DropDownButtonItem.prototype.render = function () {
    return null;
  };
  /**
   * @hidden
   */


  DropDownButtonItem.propTypes = {
    text: PropTypes.string,
    icon: PropTypes.string,
    iconClass: PropTypes.string,
    imageUrl: PropTypes.string,
    selected: PropTypes.bool,
    disabled: PropTypes.bool,
    render: PropTypes.any
  };
  return DropDownButtonItem;
}(React.Component);

export default DropDownButtonItem;