var _a;

import { CenturyViewService } from './CenturyViewService';
import { DecadeViewService } from './DecadeViewService';
import { MonthViewService } from './MonthViewService';
import { YearViewService } from './YearViewService';
import { CalendarViewEnum } from '../models/CalendarViewEnum';
var services = (_a = {}, _a[CalendarViewEnum.month] = MonthViewService, _a[CalendarViewEnum.year] = YearViewService, _a[CalendarViewEnum.decade] = DecadeViewService, _a[CalendarViewEnum.century] = CenturyViewService, _a);

var viewOffset = function viewOffset(view, offset) {
  var candidate = CalendarViewEnum[CalendarViewEnum[view + offset]];
  return candidate !== undefined ? candidate : view;
};
/**
 * @hidden
 */


var BusViewService =
/** @class */
function () {
  function BusViewService(onViewChanged) {
    this.bottom = CalendarViewEnum.month;
    this.top = CalendarViewEnum.century;
    this.onViewChanged = onViewChanged;
  }

  BusViewService.prototype.configure = function (bottom, top) {
    this.bottom = bottom;
    this.top = top;
  };

  BusViewService.prototype.service = function (view, intl) {
    return new services["" + view](intl);
  };

  BusViewService.prototype.moveDown = function (view, event) {
    this.move(view, -1, event);
  };

  BusViewService.prototype.moveUp = function (view, event) {
    this.move(view, 1, event);
  };

  BusViewService.prototype.moveToBottom = function (activeView) {
    if (activeView === this.bottom) {
      return;
    }

    this.onViewChanged({
      view: this.bottom
    });
  };

  BusViewService.prototype.canMoveDown = function (view) {
    return this.bottom < view;
  };

  BusViewService.prototype.canMoveUp = function (view) {
    return view < this.top;
  };

  BusViewService.prototype.clamp = function (view) {
    if (view < this.bottom) {
      return this.bottom;
    }

    if (view > this.top) {
      return this.top;
    }

    return view;
  };

  BusViewService.prototype.move = function (view, offset, event) {
    var candidate = this.clamp(viewOffset(view, offset));

    if (candidate === view) {
      return;
    }

    this.onViewChanged({
      view: candidate
    }, event);
  };

  return BusViewService;
}();

export { BusViewService };