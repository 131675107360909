'use strict';

module.exports = trimTrailingLines;
var line = '\n'; // Remove final newline characters from `value`.

function trimTrailingLines(value) {
  var val = String(value);
  var index = val.length;

  while (val.charAt(--index) === line) {// Empty
  }

  return val.slice(0, index + 1);
}