import { getNamedFormat, filterProps, createError } from '../utils';
var RELATIVE_TIME_FORMAT_OPTIONS = ['numeric', 'style'];

function getFormatter(_ref, getRelativeTimeFormat) {
  var locale = _ref.locale,
      formats = _ref.formats,
      onError = _ref.onError;
  var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  var format = options.format;
  var defaults = !!format && getNamedFormat(formats, 'relative', format, onError) || {};
  var filteredOptions = filterProps(options, RELATIVE_TIME_FORMAT_OPTIONS, defaults);
  return getRelativeTimeFormat(locale, filteredOptions);
}

export function formatRelativeTime(config, getRelativeTimeFormat, value, unit) {
  var options = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : {};

  if (!unit) {
    unit = 'second';
  }

  var RelativeTimeFormat = Intl.RelativeTimeFormat;

  if (!RelativeTimeFormat) {
    config.onError(createError("Intl.RelativeTimeFormat is not available in this environment.\nTry polyfilling it using \"@formatjs/intl-relativetimeformat\"\n"));
  }

  try {
    return getFormatter(config, getRelativeTimeFormat, options).format(value, unit);
  } catch (e) {
    config.onError(createError('Error formatting relative time.', e));
  }

  return String(value);
}