/* eslint-disable no-loop-func */
import parents from './parents';
import siblings from './siblings';
export default function (anchor, container) {
  var parentElements = parents(anchor);
  var containerElement = container;
  var siblingElements;
  var result;

  while (containerElement) {
    siblingElements = siblings(containerElement);
    result = parentElements.reduce(function (list, p) {
      return list.concat(siblingElements.filter(function (s) {
        return s === p;
      }));
    }, [])[0];

    if (result) {
      break;
    }

    containerElement = containerElement.parentElement;
  }

  return result;
}
;