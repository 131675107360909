import { currencyDisplay, localeCurrency } from '../cldr';
export default function formatCurrencySymbol(info, options) {
  if (options === void 0) options = {};

  if (!options.currency) {
    options.currency = localeCurrency(info, true);
  }

  var display = currencyDisplay(info, options);
  return display;
}