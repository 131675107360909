export default function pad(number, digits, right) {
  if (digits === void 0) digits = 2;
  if (right === void 0) right = false;
  var count = digits - String(number).length;
  var result = number;

  if (count > 0) {
    var padString = new Array(count + 1).join("0");
    result = right ? number + padString : padString + number;
  }

  return result;
}