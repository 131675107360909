import documentElement from './document';

var offsetParent = function offsetParent(element) {
  var offsetParent = element.offsetParent;

  while (offsetParent && offsetParent.style.position === "static") {
    offsetParent = offsetParent.offsetParent;
  }

  return offsetParent || documentElement(element);
};

export default offsetParent;