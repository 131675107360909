import * as space from './space';
import * as entry from './entry';
import * as asset from './asset';
import * as contentType from './content-type';
import * as locale from './locale';
export default {
  space: space,
  entry: entry,
  asset: asset,
  contentType: contentType,
  locale: locale
};