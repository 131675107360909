/**
 * @hidden
 */
var FormComponent =
/** @class */
function () {
  function FormComponent() {}

  return FormComponent;
}();

export { FormComponent };