import { cloneDate, isEqual } from '@progress/kendo-date-math';
import { getDate } from '@progress/kendo-date-math';
import { EMPTY_SELECTIONRANGE } from './calendar/models/SelectionRange';
/**
 * @hidden
 */

export var isEqualRange = function isEqualRange(initial, updated) {
  var _a = initial || EMPTY_SELECTIONRANGE,
      initialStart = _a.start,
      initialEnd = _a.end;

  var _b = updated || EMPTY_SELECTIONRANGE,
      updatedStart = _b.start,
      updatedEnd = _b.end;

  if (initialStart === null || initialEnd === null || updatedStart === null || updatedEnd === null) {
    return false;
  }

  return isEqual(initialStart, updatedStart) && isEqual(initialEnd, updatedEnd);
};
/**
 * @hidden
 */

export var viewInRange = function viewInRange(candidate, min, max) {
  if (min === undefined || max === undefined) {
    return candidate;
  }

  return min <= candidate && candidate <= max ? candidate : candidate < min ? min : max;
};
/**
 * @hidden
 */

export var MIDNIGHT_DATE = new Date(1980, 0, 1);
/**
 * @hidden
 */

export var MIN_DATE = new Date(1900, 0, 1);
/**
 * @hidden
 */

export var MAX_DATE = new Date(2099, 11, 31);
/**
 * @hidden
 */

export var MIN_TIME = new Date(1980, 0, 1);
/**
 * @hidden
 */

export var MAX_TIME = new Date(1980, 0, 1, 23, 59, 59);

var isSet = function isSet(value) {
  return value !== null && value !== undefined;
};
/**
 * @hidden
 */


export var isValidRange = function isValidRange(min, max) {
  return !isSet(min) || !isSet(max) || min <= max;
};
/**
 * @hidden
 */

export var setTime = function setTime(origin, candidate) {
  var date = cloneDate(origin);
  date.setHours(candidate.getHours(), candidate.getMinutes(), candidate.getSeconds(), candidate.getMilliseconds());
  return date;
};
/**
 * @hidden
 */

export var getToday = function getToday() {
  return getDate(new Date());
};
/**
 * @hidden
 */

export var isInRange = function isInRange(candidate, min, max) {
  return !candidate || !(min && min > candidate || max && max < candidate);
};
/**
 * @hidden
 */

export var isInRangePicker = function isInRangePicker(candidate, min, max) {
  return candidate === null || !(min && getDate(min) > getDate(candidate) || max && getDate(max) < getDate(candidate));
};
/**
 * @hidden
 */

export var isInSelectionRange = function isInSelectionRange(value, selectionRange) {
  var _a = selectionRange || EMPTY_SELECTIONRANGE,
      start = _a.start,
      end = _a.end;

  if (!start || !end) {
    return false;
  }

  return start < value && value < end;
};
/**
 * @hidden
 */

export var range = function range(start, end, step) {
  if (step === void 0) {
    step = 1;
  }

  var result = [];

  for (var i = start; i < end; i = i + step) {
    result.push(i);
  }

  return result;
};
/**
 * @hidden
 */

export var shiftWeekNames = function shiftWeekNames(names, offset) {
  return names.slice(offset).concat(names.slice(0, offset));
};
/**
 * @hidden
 */

export var dateInRange = function dateInRange(candidate, min, max) {
  if (!candidate) {
    return candidate;
  }

  if (min && candidate < min) {
    return cloneDate(min);
  }

  if (max && candidate > max) {
    return cloneDate(max);
  }

  return candidate;
};
/**
 * @hidden
 */

export var domContainerFactory = function domContainerFactory(type) {
  return function (children, classes, styles) {
    if (classes === void 0) {
      classes = '';
    }

    if (styles === void 0) {
      styles = {};
    }

    var container = document.createElement(type);
    container.className = classes;
    Object.keys(styles).map(function (key) {
      return container.style[key] = styles[key];
    });

    if (typeof children === 'string') {
      container.innerHTML = children || '';
    } else {
      (children || []).forEach(function (child) {
        return child && container.appendChild(child);
      });
    }

    return container;
  };
};
/**
 * @hidden
 */

export var isDocumentAvailable = function isDocumentAvailable() {
  return typeof document !== 'undefined';
};