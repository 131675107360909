"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = isObject;

function isObject(value) {
  return value instanceof Object && !Array.isArray(value);
}

module.exports = exports["default"];