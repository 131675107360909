"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = prefixValue;

function prefixValue(plugins, property, value, style, metaData) {
  for (var i = 0, len = plugins.length; i < len; ++i) {
    var processedValue = plugins[i](property, value, style, metaData); // we can stop processing if a value is returned
    // as all plugin criteria are unique

    if (processedValue) {
      return processedValue;
    }
  }
}

module.exports = exports["default"];