import errorDetails from './error-details';
var formatRegExp = /\{(\d+)}?\}/g;

var IntlError = function IntlError(ref) {
  var name = ref.name;
  var message = ref.message;

  if (!name || !message) {
    throw new Error("{ name: string, message: string } object is required!");
  }

  this.name = name;
  this.message = message;
};

IntlError.prototype.formatMessage = function formatMessage() {
  var values = [],
      len = arguments.length;

  while (len--) {
    values[len] = arguments[len];
  }

  var flattenValues = flatten(values);
  var formattedMessage = this.message.replace(formatRegExp, function (match, index) {
    return flattenValues[parseInt(index, 10)];
  });
  return this.name + ": " + formattedMessage;
};

IntlError.prototype.error = function error() {
  var values = [],
      len = arguments.length;

  while (len--) {
    values[len] = arguments[len];
  }

  return new Error(this.formatMessage(values));
};

var flatten = function flatten(arr) {
  return arr.reduce(function (a, b) {
    return a.concat(b);
  }, []);
};

var toIntlErrors = function toIntlErrors(errors) {
  var predicate = function predicate(prev, name) {
    prev[name] = new IntlError({
      name: name,
      message: errors[name]
    });
    return prev;
  };

  return Object.keys(errors).reduce(predicate, {});
};

var errors = toIntlErrors(errorDetails);
export { errors, IntlError, toIntlErrors };