import { cldr, getLocaleInfo } from './info';
import { errors } from '../errors';
import localeTerritory from './territory';
import parseRangeDate from './parse-range-date';
/* eslint-disable consistent-return */

var NoCurrency = errors.NoCurrency;
var NoCurrencyDisplay = errors.NoCurrencyDisplay;
var NoSupplementalCurrency = errors.NoSupplementalCurrency;
var NoCurrencyRegion = errors.NoCurrencyRegion;
var NoValidCurrency = errors.NoValidCurrency;
var DEFAULT_CURRENCY_FRACTIONS = 2;
var SYMBOL = "symbol";
var INVALID_CURRENCY_CODE = 'XXX';
var GLOBAL_CURRENCIES = {
  '001': 'USD',
  // 001 refers to world. not sure if it is correct to assume USD but seems better than throw an error
  '150': 'EUR' // 150 territory for Europe

};

function getCurrencyInfo(locale, currency, throwIfNoValid) {
  var info = getLocaleInfo(locale);
  var currencies = info.numbers.currencies;

  if (!currencies) {
    if (throwIfNoValid) {
      throw NoCurrency.error();
    }

    return;
  }

  var currencyDisplayInfo = currencies[currency];

  if (!currencyDisplayInfo) {
    if (throwIfNoValid) {
      throw NoCurrencyDisplay.error();
    }

    return;
  }

  return currencyDisplayInfo;
}

function lengthComparer(a, b) {
  return b.length - a.length;
}

function regionCurrency(regionCurrencies) {
  var latestValidUntil, latestValidUntilRange;
  var latestStillValid, latestStillValidDate;

  for (var idx = 0; idx < regionCurrencies.length; idx++) {
    var currency = regionCurrencies[idx];
    var code = Object.keys(currency)[0];
    var info = currency[code];

    if (code !== INVALID_CURRENCY_CODE && info._tender !== 'false' && info._from) {
      if (!info._to) {
        var stillValidDate = parseRangeDate(info._from);

        if (!latestStillValidDate || latestStillValidDate < stillValidDate) {
          latestStillValid = code;
          latestStillValidDate = stillValidDate;
        }
      } else if (!latestStillValid) {
        var validFrom = parseRangeDate(info._from);
        var validTo = parseRangeDate(info._to);

        if (!latestValidUntilRange || latestValidUntilRange.to < validTo || latestValidUntilRange.from < validFrom) {
          latestValidUntil = code;
          latestValidUntilRange = {
            from: validFrom,
            to: validTo
          };
        }
      }
    }
  }

  return latestStillValid || latestValidUntil;
}

export function currencyDisplays(locale, currency, throwIfNoValid) {
  if (throwIfNoValid === void 0) throwIfNoValid = true;
  var currencyInfo = getCurrencyInfo(locale, currency, throwIfNoValid);

  if (!currencyInfo) {
    return;
  }

  if (!currencyInfo.displays) {
    var displays = [currency];

    for (var field in currencyInfo) {
      displays.push(currencyInfo[field]);
    }

    displays.sort(lengthComparer);
    currencyInfo.displays = displays;
  }

  return currencyInfo.displays;
}
export function currencyDisplay(locale, options) {
  var value = options.value;
  var currency = options.currency;
  var currencyDisplay = options.currencyDisplay;
  if (currencyDisplay === void 0) currencyDisplay = SYMBOL;

  if (currencyDisplay === "code") {
    return currency;
  }

  var currencyInfo = getCurrencyInfo(locale, currency, true);
  var result;

  if (currencyDisplay === SYMBOL) {
    result = currencyInfo["symbol-alt-narrow"] || currencyInfo[SYMBOL];
  } else {
    if (typeof value === undefined || value !== 1) {
      result = currencyInfo["displayName-count-other"];
    } else {
      result = currencyInfo["displayName-count-one"];
    }
  }

  return result;
}
export function currencyFractionOptions(code) {
  var minimumFractionDigits = DEFAULT_CURRENCY_FRACTIONS;
  var maximumFractionDigits = DEFAULT_CURRENCY_FRACTIONS;
  var fractions = ((cldr.supplemental.currencyData || {}).fractions || {})[code];

  if (fractions && fractions._digits) {
    maximumFractionDigits = minimumFractionDigits = parseInt(fractions._digits, 10);
  }

  return {
    minimumFractionDigits: minimumFractionDigits,
    maximumFractionDigits: maximumFractionDigits
  };
}
export function territoryCurrencyCode(territory, throwIfNoValid) {
  if (throwIfNoValid === void 0) throwIfNoValid = true;

  if (GLOBAL_CURRENCIES[territory]) {
    return GLOBAL_CURRENCIES[territory];
  }

  var currencyData = cldr.supplemental.currencyData;

  if (!currencyData) {
    if (throwIfNoValid) {
      throw NoSupplementalCurrency.error();
    }

    return;
  }

  var regionCurrencies = currencyData.region[territory];

  if (!regionCurrencies) {
    if (throwIfNoValid) {
      throw NoCurrencyRegion.error(territory);
    }

    return;
  }

  var currencyCode = regionCurrency(regionCurrencies);
  return currencyCode;
}
export function localeCurrency(locale, throwIfNoValid) {
  var info = getLocaleInfo(locale);
  var numbers = info.numbers;

  if (!numbers.localeCurrency) {
    var currency = territoryCurrencyCode(localeTerritory(info), throwIfNoValid);

    if (!currency && throwIfNoValid) {
      throw NoValidCurrency.error(info.name);
    }

    numbers.localeCurrency = currency;
  }

  return numbers.localeCurrency;
}