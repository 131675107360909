'use strict';

var locate = require('../locate/break');

module.exports = hardBreak;
hardBreak.locator = locate;
var MIN_BREAK_LENGTH = 2;

function hardBreak(eat, value, silent) {
  var length = value.length;
  var index = -1;
  var queue = '';
  var character;

  while (++index < length) {
    character = value.charAt(index);

    if (character === '\n') {
      if (index < MIN_BREAK_LENGTH) {
        return;
      }
      /* istanbul ignore if - never used (yet) */


      if (silent) {
        return true;
      }

      queue += character;
      return eat(queue)({
        type: 'break'
      });
    }

    if (character !== ' ') {
      return;
    }

    queue += character;
  }
}