import { align, applyLocationOffset, boundingOffset, getWindowViewPort, isBodyOffset, offset, positionWithScroll, restrictToView, addScroll, removeScroll, scrollPosition, siblingContainer } from '@progress/kendo-popup-common';
import { isDocumentAvailable, isWindowAvailable, HAS_RELATIVE_STACKING_CONTEXT, scrollableParents, zIndex } from '../util';
/**
 * @hidden
 */

var DOMService =
/** @class */
function () {
  function DOMService() {}

  DOMService.prototype.addOffset = function (current, addition) {
    return {
      left: current.left + addition.left,
      top: current.top + addition.top
    };
  };

  DOMService.prototype.align = function (settings) {
    return align(settings);
  };

  DOMService.prototype.boundingOffset = function (el) {
    return boundingOffset(el);
  };

  DOMService.prototype.getWindow = function () {
    return isWindowAvailable() ? window : null;
  };

  DOMService.prototype.isBodyOffset = function (el) {
    return isBodyOffset(el);
  };

  DOMService.prototype.hasOffsetParent = function (el) {
    if (!el) {
      return false;
    }

    var offsetParentEl = el.offsetParent;
    return offsetParentEl && !(offsetParentEl.nodeName === 'BODY' && window.getComputedStyle(offsetParentEl).position === 'static');
  };

  DOMService.prototype.offset = function (el) {
    if (!el) {
      return null;
    }

    return offset(el);
  };

  DOMService.prototype.staticOffset = function (element) {
    if (!element) {
      return null;
    }

    var _a = element.style,
        left = _a.left,
        top = _a.top;
    element.style.left = '0px';
    element.style.top = '0px';
    var currentOffset = offset(element);
    element.style.left = left;
    element.style.top = top;
    return currentOffset;
  };

  DOMService.prototype.position = function (element, popup) {
    if (!element || !popup) {
      return null;
    }

    var parentSibling = siblingContainer(element, popup);
    return positionWithScroll(element, parentSibling);
  };

  DOMService.prototype.relativeOffset = function (el, currentLocation) {
    return applyLocationOffset(this.offset(el), currentLocation, this.isBodyOffset(el));
  };

  DOMService.prototype.addScroll = function (rect, scroll) {
    return addScroll(rect, scroll);
  };

  DOMService.prototype.removeScroll = function (rect, scroll) {
    return removeScroll(rect, scroll);
  };

  DOMService.prototype.restrictToView = function (settings) {
    return restrictToView(settings);
  };

  DOMService.prototype.scrollPosition = function (el) {
    return scrollPosition(el);
  };

  DOMService.prototype.scrollableParents = function (el) {
    return scrollableParents(el);
  };

  DOMService.prototype.stackingElementOffset = function (el) {
    var relativeContextElement = this.getRelativeContextElement(el);

    if (!relativeContextElement) {
      return null;
    }

    return offset(relativeContextElement);
  };

  DOMService.prototype.stackingElementScroll = function (el) {
    var relativeContextElement = this.getRelativeContextElement(el);

    if (!relativeContextElement) {
      return {
        x: 0,
        y: 0
      };
    }

    return {
      x: relativeContextElement.scrollLeft,
      y: relativeContextElement.scrollTop
    };
  };

  DOMService.prototype.stackingElementViewPort = function (el) {
    var relativeContextElement = this.getRelativeContextElement(el);

    if (!relativeContextElement) {
      return null;
    }

    return {
      height: relativeContextElement.scrollHeight,
      width: relativeContextElement.scrollWidth
    };
  };

  DOMService.prototype.getRelativeContextElement = function (el) {
    if (!el || !HAS_RELATIVE_STACKING_CONTEXT) {
      return null;
    }

    var parent = el.parentElement;

    while (parent) {
      if (window.getComputedStyle(parent).transform !== 'none') {
        return parent;
      }

      parent = parent.parentElement;
    }

    return null;
  };

  DOMService.prototype.useRelativePosition = function (el) {
    return !!this.getRelativeContextElement(el);
  };

  DOMService.prototype.windowViewPort = function (el) {
    return getWindowViewPort(el);
  };

  DOMService.prototype.zIndex = function (anchor, container) {
    return zIndex(anchor, container);
  };

  DOMService.prototype.zoomLevel = function () {
    if (!isDocumentAvailable() || !isWindowAvailable()) {
      return 1;
    }

    return parseFloat((document.documentElement.clientWidth / window.innerWidth).toFixed(2));
  };

  DOMService.prototype.isZoomed = function () {
    return this.zoomLevel() > 1;
  };

  return DOMService;
}();

export { DOMService };