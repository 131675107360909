var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

import * as React from 'react';
import * as PropTypes from 'prop-types';
import KendoDraggable from '@telerik/kendo-draggable';
/**
 * @hidden
 */

var Draggable =
/** @class */
function (_super) {
  __extends(Draggable, _super);

  function Draggable() {
    var _this = _super !== null && _super.apply(this, arguments) || this;
    /**
     * @hidden
     */


    _this.element = null;
    _this.draggable = new KendoDraggable({
      press: function press(event) {
        if (_this.element && _this.props.onPress) {
          _this.props.onPress.call(undefined, {
            target: _this,
            event: event,
            element: _this.element
          });
        }
      },
      drag: function drag(event) {
        if (_this.element && _this.props.onDrag) {
          _this.props.onDrag.call(undefined, {
            target: _this,
            event: event,
            element: _this.element
          });
        }
      },
      release: function release(event) {
        if (_this.element && _this.props.onRelease) {
          _this.props.onRelease.call(undefined, {
            target: _this,
            event: event
          });
        }
      }
    });

    _this.assingRef = function (element) {
      _this.element = element;
    };

    return _this;
  }

  Draggable.prototype.componentDidMount = function () {
    if (this.element) {
      this.draggable.bindTo(this.element);
    }
  };

  Draggable.prototype.componentWillUnmount = function () {
    this.draggable.destroy();
  };

  Draggable.prototype.render = function () {
    return React.cloneElement(React.Children.only(this.props.children), {
      ref: this.assingRef
    });
  };

  Draggable.propTypes = {
    children: PropTypes.element.isRequired
  };
  return Draggable;
}(React.Component);

export { Draggable };