import jsonStringifySafe from 'json-stringify-safe';
export default function mixinStringifySafe(data) {
  return Object.defineProperty(data, 'stringifySafe', {
    enumerable: false,
    configurable: false,
    writable: false,
    value: function value() {
      var serializer = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      var indent = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
      return jsonStringifySafe(this, serializer, indent, function (key, value) {
        return {
          sys: {
            type: 'Link',
            linkType: 'Entry',
            id: value.sys.id,
            circular: true
          }
        };
      });
    }
  });
}