import { CalendarViewEnum } from '../models/CalendarViewEnum';
import { domContainerFactory as containerFactory, isDocumentAvailable } from '../../utils';
/**
 * @hidden
 */

var DOMService =
/** @class */
function () {
  function DOMService() {
    this.didCalculate = false;
  }

  DOMService.prototype.ensureHeights = function () {
    if (this.calendarHeight !== undefined) {
      return;
    }

    this.calculateHeights();
  };

  DOMService.prototype.calculateHeights = function (container) {
    var _this = this;

    if (!isDocumentAvailable()) {
      return;
    }

    var div = containerFactory('div');
    var ul = containerFactory('ul');
    var li = containerFactory('li');
    var td = containerFactory('td');
    var th = containerFactory('th');
    var tr = containerFactory('tr');
    var tbody = containerFactory('tbody');
    var thead = containerFactory('thead');
    var table = containerFactory('table');

    var monthHeader = function monthHeader() {
      return div("\n            <span class=\"k-calendar-title\">March 2017</span>\n            <span class=\"k-calendar-today\">TODAY</span>\n        ", 'k-calendar-header');
    };

    var monthWeekHeader = function monthWeekHeader() {
      return table([thead([tr([th('MO')])])], 'k-calendar-weekdays');
    };

    var repeat = function repeat(count, mapper) {
      return new Array(count).fill('1').map(mapper);
    };

    var content = function content(rows, cells) {
      if (cells === void 0) {
        cells = 1;
      }

      return table([tbody([tr([th('1')])].concat(repeat(rows, function () {
        return tr(repeat(cells, function (c) {
          return td("<span class=\"k-link\">" + c + "</span>");
        }));
      })))]);
    };

    var scrollable = function scrollable(children) {
      return div(children, 'k-content k-scrollable');
    };

    var view = function view(contentElement, className, renderWeekHeader) {
      return div(renderWeekHeader ? [monthHeader(), monthWeekHeader(), scrollable([contentElement, contentElement])] : [monthHeader(), scrollable([contentElement, contentElement])], className, {
        left: '-10000px',
        position: 'absolute'
      });
    };

    var navigationList = function () {
      var navElement;
      return function () {
        if (!isDocumentAvailable) {
          return null;
        }

        if (!navElement) {
          navElement = div([scrollable([ul([li('<span>FEB</span>')])])], 'k-calendar-navigation', {
            left: '0px',
            position: 'absolute'
          });
        }

        return navElement;
      };
    }();

    var viewFactory = function viewFactory(_a, className, renderWeekHeader) {
      var cells = _a.cells,
          rows = _a.rows;
      var viewElement;
      return function () {
        if (!isDocumentAvailable) {
          return null;
        }

        if (!viewElement) {
          viewElement = view(content(rows, cells), className, renderWeekHeader);
        }

        return viewElement;
      };
    };

    var getScrollable = function getScrollable(element) {
      return element.querySelector('.k-scrollable');
    };

    var horizontal = function horizontal(element) {
      var scrollableElement = getScrollable(element);
      scrollableElement.className = scrollableElement.className + " k-scrollable-horizontal";
      return element;
    };

    var monthView = viewFactory({
      cells: 7,
      rows: 6
    }, 'k-calendar-view k-calendar-monthview', true);
    var yearView = viewFactory({
      cells: 5,
      rows: 3
    }, 'k-calendar-view k-calendar-yearview', false);
    var decadeView = viewFactory({
      cells: 5,
      rows: 2
    }, 'k-calendar-view k-calendar-decadeview', false);

    var horzMonthView = function horzMonthView() {
      return horizontal(monthView());
    };

    var horzYearView = function horzYearView() {
      return horizontal(yearView());
    };

    var horzDecadeView = function horzDecadeView() {
      return horizontal(decadeView());
    };

    var height = function height(element) {
      return parseFloat(window.getComputedStyle(element).height) || element.offsetHeight;
    };

    var width = function width(element) {
      var styles = window.getComputedStyle(element);
      var computed = parseFloat(styles.width) + parseFloat(styles.paddingLeft) + parseFloat(styles.paddingRight);
      return computed || element.offsetWidth;
    };

    var getBody = function getBody(element) {
      return element.querySelector('tbody');
    };

    this.didCalculate = true;

    if (container) {
      this.hostContainer = container;
    }

    this.batch(monthView(), function (contentElement) {
      var viewElement = getBody(contentElement);
      _this.calendarHeight = height(contentElement);
      _this.monthViewHeight = height(viewElement);
      _this.headerHeight = height(viewElement.children[0]);
      _this.scrollableContentHeight = height(getScrollable(contentElement));
    });
    this.batch(horzMonthView(), function (contentElement) {
      var viewElement = getBody(contentElement);
      _this.calendarWidth = width(contentElement);
      _this.monthViewWidth = width(viewElement);
      _this.scrollableContentWidth = width(getScrollable(contentElement));
    });
    this.batch(yearView(), function (contentElement) {
      _this.yearViewHeight = height(getBody(contentElement));
      _this.scrollableYearContentHeight = height(getScrollable(contentElement));
    });
    this.batch(horzYearView(), function (contentElement) {
      _this.yearViewWidth = width(getBody(contentElement));
    });
    this.batch(decadeView(), function (contentElement) {
      _this.decadeViewHeight = height(getBody(contentElement));
      _this.centuryViewHeight = _this.decadeViewHeight;
    });
    this.batch(horzDecadeView(), function (contentElement) {
      _this.decadeViewWidth = width(getBody(contentElement));
      _this.centuryViewWidth = _this.decadeViewWidth;
    });
    this.batch(navigationList(), function (contentElement) {
      _this.navigationItemHeight = height(contentElement.querySelector('li'));
    });
  };

  DOMService.prototype.viewHeight = function (viewType) {
    return this.viewDimension(viewType, 'height');
  };

  DOMService.prototype.viewWidth = function (viewType) {
    return this.viewDimension(viewType, 'width');
  };

  DOMService.prototype.viewDimension = function (viewType, dimension) {
    var viewProp = dimension === 'height' ? 'ViewHeight' : 'ViewWidth';

    switch (viewType) {
      case CalendarViewEnum.month:
        return this["month" + viewProp];

      case CalendarViewEnum.year:
        return this["year" + viewProp];

      case CalendarViewEnum.decade:
        return this["decade" + viewProp];

      case CalendarViewEnum.century:
        return this["century" + viewProp];

      default:
        return 1;
    }
  };

  DOMService.prototype.batch = function (contentElement, action) {
    var hostContainer = this.hostContainer || document.body;
    var appendedContent = hostContainer.appendChild(contentElement);
    action(appendedContent);
    hostContainer.removeChild(appendedContent);
  };

  return DOMService;
}();

export { DOMService };