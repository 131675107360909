var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

var __rest = this && this.__rest || function (s, e) {
  var t = {};

  for (var p in s) {
    if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  }

  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0) t[p[i]] = s[p[i]];
  }
  return t;
};

import * as React from 'react';
import * as PropTypes from 'prop-types';
import { classNames, noop } from '@progress/kendo-react-common';
import { CSSTransition } from 'react-transition-group';
import util from './util';
var styles = util.styles;

var AnimationChild =
/** @class */
function (_super) {
  __extends(AnimationChild, _super);

  function AnimationChild() {
    return _super !== null && _super.apply(this, arguments) || this;
  }

  Object.defineProperty(AnimationChild.prototype, "element", {
    /**
     * The element that is being animated.
     */
    get: function get() {
      return this._element;
    },
    enumerable: true,
    configurable: true
  });
  /**
   * @hidden
   */

  AnimationChild.prototype.render = function () {
    var _this = this;

    var _a = this.props,
        children = _a.children,
        style = _a.style,
        appear = _a.appear,
        enter = _a.enter,
        exit = _a.exit,
        transitionName = _a.transitionName,
        transitionEnterDuration = _a.transitionEnterDuration,
        transitionExitDuration = _a.transitionExitDuration,
        className = _a.className,
        _onEnter = _a.onEnter,
        _onEntering = _a.onEntering,
        _onEntered = _a.onEntered,
        _onExit = _a.onExit,
        _onExiting = _a.onExiting,
        _onExited = _a.onExited,
        mountOnEnter = _a.mountOnEnter,
        unmountOnExit = _a.unmountOnExit,
        animationEnteringStyle = _a.animationEnteringStyle,
        animationEnteredStyle = _a.animationEnteredStyle,
        animationExitingStyle = _a.animationExitingStyle,
        animationExitedStyle = _a.animationExitedStyle,
        other = __rest(_a, ["children", "style", "appear", "enter", "exit", "transitionName", "transitionEnterDuration", "transitionExitDuration", "className", "onEnter", "onEntering", "onEntered", "onExit", "onExiting", "onExited", "mountOnEnter", "unmountOnExit", "animationEnteringStyle", "animationEnteredStyle", "animationExitingStyle", "animationExitedStyle"]);

    var childAnimationContainerClassNames = classNames(className, styles['child-animation-container']);

    var defaultStyle = __assign({
      transitionDelay: '0ms'
    }, style);

    var animationStyle = {
      entering: __assign({
        transitionDuration: transitionEnterDuration + "ms"
      }, animationEnteringStyle),
      entered: __assign({}, animationEnteredStyle),
      exiting: __assign({
        transitionDuration: transitionExitDuration + "ms"
      }, animationExitingStyle),
      exited: __assign({}, animationExitedStyle)
    };
    var transitionProps = {
      in: this.props.in,
      appear: appear,
      enter: enter,
      exit: exit,
      mountOnEnter: mountOnEnter,
      unmountOnExit: unmountOnExit,
      timeout: {
        enter: transitionEnterDuration,
        exit: transitionExitDuration
      },
      onEnter: function onEnter(e) {
        if (_onEnter) {
          _onEnter.call(undefined, {
            animatedElement: e,
            target: _this
          });
        }
      },
      onEntering: function onEntering(e) {
        if (_onEntering) {
          _onEntering.call(undefined, {
            animatedElement: e,
            target: _this
          });
        }
      },
      onEntered: function onEntered(e) {
        if (_onEntered) {
          _onEntered.call(undefined, {
            animatedElement: e,
            target: _this
          });
        }
      },
      onExit: function onExit(e) {
        if (_onExit) {
          _onExit.call(undefined, {
            animatedElement: e,
            target: _this
          });
        }
      },
      onExiting: function onExiting(e) {
        if (_onExiting) {
          _onExiting.call(undefined, {
            animatedElement: e,
            target: _this
          });
        }
      },
      onExited: function onExited(e) {
        if (_onExited) {
          _onExited.call(undefined, {
            animatedElement: e,
            target: _this
          });
        }
      },
      classNames: {
        appear: styles[transitionName + "-appear"] || transitionName + "-appear",
        appearActive: styles[transitionName + "-appear-active"] || transitionName + "-appear-active",
        enter: styles[transitionName + "-enter"] || transitionName + "-enter",
        enterActive: styles[transitionName + "-enter-active"] || transitionName + "-enter-active",
        exit: styles[transitionName + "-exit"] || transitionName + "-exit",
        exitActive: styles[transitionName + "-exit-active"] || transitionName + "-exit-active"
      }
    };
    return React.createElement(CSSTransition, __assign({}, transitionProps, other), function (status) {
      return React.createElement("div", {
        style: __assign({}, defaultStyle, animationStyle[status]),
        className: childAnimationContainerClassNames,
        ref: function ref(div) {
          _this._element = div;
        }
      }, children);
    });
  };
  /**
   * @hidden
   */


  AnimationChild.propTypes = {
    in: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    transitionName: PropTypes.string.isRequired,
    className: PropTypes.string,
    appear: PropTypes.bool,
    enter: PropTypes.bool,
    exit: PropTypes.bool,
    transitionEnterDuration: PropTypes.number.isRequired,
    transitionExitDuration: PropTypes.number.isRequired,
    mountOnEnter: PropTypes.bool,
    unmountOnExit: PropTypes.bool,
    animationEnteringStyle: PropTypes.object,
    animationEnteredStyle: PropTypes.object,
    animationExitingStyle: PropTypes.object,
    animationExitedStyle: PropTypes.object
  };
  /**
   * @hidden
   */

  AnimationChild.defaultProps = {
    mountOnEnter: true,
    unmountOnExit: false,
    onEnter: noop,
    onEntering: noop,
    onEntered: noop,
    onExit: noop,
    onExiting: noop,
    onExited: noop,
    animationEnteringStyle: {},
    animationEnteredStyle: {},
    animationExitingStyle: {},
    animationExitedStyle: {}
  };
  return AnimationChild;
}(React.Component);

export { AnimationChild };