export default function (element, until) {
  var result = [];
  var next = element.parentNode;

  while (next) {
    result.push(next);

    if (next === until) {
      break;
    }

    next = next.parentNode;
  }

  return result;
}
;