var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

import * as React from 'react';
import { Header } from './Header';
import { View } from './View';
import { CalendarViewEnum } from '../models';
import { classNames } from '@progress/kendo-react-common';
import { cloneDate } from '@progress/kendo-date-math';
import { MAX_DATE, MIN_DATE } from '../../defaults';
var DEFAULT_FETCH_MONTHS_COUNT = 2;
/**
 * @hidden
 */

var HorizontalViewList =
/** @class */
function (_super) {
  __extends(HorizontalViewList, _super);

  function HorizontalViewList(props) {
    var _this = _super.call(this, props) || this;

    _this.isActive = false;

    _this.focusActiveDate = function () {
      if (!_this._element) {
        return;
      }

      var focusedCell = _this._element.querySelector("td.k-state-focused");

      var nextActiveCell = _this._element.querySelector('.k-state-pending-focus');

      if (focusedCell && focusedCell[0]) {
        focusedCell[0].classList.remove('k-state-focused');
      }

      if (nextActiveCell) {
        nextActiveCell.classList.add('k-state-focused');
      }

      _this.isActive = true;
    };

    _this.blurActiveDate = function () {
      if (!_this._element) {
        return;
      }

      var focusedCell = _this._element.querySelector("td.k-state-focused");

      if (focusedCell) {
        focusedCell.classList.remove('k-state-focused');
      }

      _this.isActive = false;
    };

    _this.rotateSelectionRange = function (selectionRange) {
      if (selectionRange.start === null || selectionRange.end === null) {
        return selectionRange;
      }

      var needRotate = selectionRange.end < selectionRange.start;
      return {
        start: needRotate ? selectionRange.end : selectionRange.start,
        end: needRotate ? selectionRange.start : selectionRange.end
      };
    };

    _this.handleTodayClick = function (event) {
      _this.handleDateChange.call(undefined, event, true);
    };

    _this.handleDateChange = function (event, isTodayClick) {
      if (isTodayClick === void 0) {
        isTodayClick = false;
      }

      var onChange = _this.props.onChange;

      if (onChange) {
        var args = {
          syntheticEvent: event.syntheticEvent,
          nativeEvent: event.nativeEvent,
          value: cloneDate(event.value),
          target: _this,
          isTodayClick: isTodayClick
        };
        onChange.call(undefined, args);
      }
    };

    _this.lastView = props.activeView;
    return _this;
  }

  Object.defineProperty(HorizontalViewList.prototype, "element", {
    get: function get() {
      return this._element;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(HorizontalViewList.prototype, "weekNumber", {
    get: function get() {
      return Boolean(this.props.showWeekNumbers && this.props.activeView === CalendarViewEnum.month);
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(HorizontalViewList.prototype, "min", {
    get: function get() {
      return this.props.min !== undefined ? this.props.min : HorizontalViewList.defaultProps.min;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(HorizontalViewList.prototype, "max", {
    get: function get() {
      return this.props.max !== undefined ? this.props.max : HorizontalViewList.defaultProps.max;
    },
    enumerable: true,
    configurable: true
  });

  HorizontalViewList.prototype.componentDidUpdate = function () {
    if (this.isActive) {
      this.focusActiveDate();
    }

    this.lastView = this.props.activeView;
    this.lastViewsCount = this.props.views || HorizontalViewList.defaultProps.views;
  };

  HorizontalViewList.prototype.render = function () {
    var _this = this;

    var didViewChange = this.lastView !== this.props.activeView;
    var isDateInList = this.dates && this.props.service.isInArray(this.props.focusedDate, this.dates);
    var didViewsCountChange = this.lastViewsCount !== this.props.views;

    if (!isDateInList || didViewChange || didViewsCountChange) {
      this.dates = this.props.service.datesList(this.props.focusedDate, this.props.views || HorizontalViewList.defaultProps.views);
    }

    var activeDate = cloneDate(this.dates && this.dates[0] ? this.dates[0] : undefined);
    var selectionRange = this.props.allowReverse ? this.rotateSelectionRange(this.props.selectionRange) : this.props.selectionRange;
    var rootClassNames = classNames('k-calendar-view', {
      'k-calendar-monthview': this.props.activeView === CalendarViewEnum.month,
      'k-calendar-yearview': this.props.activeView === CalendarViewEnum.year,
      'k-calendar-decadeview': this.props.activeView === CalendarViewEnum.decade,
      'k-calendar-centuryview': this.props.activeView === CalendarViewEnum.century
    });
    return React.createElement("div", {
      ref: function ref(el) {
        _this._element = el;
      },
      className: rootClassNames
    }, React.createElement(Header, {
      key: ".kendo.calendar.header." + activeDate.getTime(),
      activeView: this.props.activeView,
      currentDate: activeDate,
      min: this.min,
      max: this.max,
      rangeLength: this.props.views,
      onTodayClick: this.handleTodayClick,
      bus: this.props.bus,
      service: this.props.service,
      headerTitle: this.props.headerTitle
    }), React.createElement("table", {
      className: "k-content",
      key: activeDate.getTime()
    }, this.dates.map(function (date) {
      return React.createElement(View, {
        bus: _this.props.bus,
        service: _this.props.service,
        key: date.getTime(),
        direction: "horizontal",
        activeView: _this.props.activeView,
        cellUID: _this.props.cellUID,
        viewDate: date,
        min: _this.min,
        max: _this.max,
        focusedDate: _this.props.focusedDate,
        selectionRange: selectionRange,
        selectedDate: _this.props.value,
        showWeekNumbers: _this.weekNumber,
        onChange: _this.handleDateChange,
        onCellEnter: _this.props.onCellEnter,
        cell: _this.props.cell,
        weekCell: _this.props.weekCell
      });
    })));
  };

  HorizontalViewList.defaultProps = {
    showWeekNumbers: false,
    views: DEFAULT_FETCH_MONTHS_COUNT,
    take: DEFAULT_FETCH_MONTHS_COUNT,
    allowReverse: true,
    min: MIN_DATE,
    max: MAX_DATE
  };
  return HorizontalViewList;
}(React.Component);

export { HorizontalViewList };