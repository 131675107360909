import { getDate } from './get-date';
import { isEqual } from './is-equal';
/**
 * A function that compares the date portions of 2 dates.
 *
 * @param candidate - The candidate date.
 * @param expected - The expected date.
 * @returns - A Boolean value whether the values are equal.
 *
 * @example
 * ```ts-no-run
 * isEqualDate(new Date(2016, 0, 1, 10), new Date(2016, 0, 1, 20)); // true
 * isEqualDate(new Date(2016, 0, 1, 10), new Date(2016, 0, 2, 10)); // false
 * ```
 */

export var isEqualDate = function isEqualDate(candidate, expected) {
  if (!candidate && !expected) {
    return true;
  }

  return candidate && expected && isEqual(getDate(candidate), getDate(expected));
};