var cachedWidth = 0;
export default function scrollbarWidth() {
  if (!cachedWidth && typeof document !== 'undefined') {
    var div = document.createElement("div");
    div.style.cssText = "overflow:scroll;overflow-x:hidden;zoom:1;clear:both;display:block";
    div.innerHTML = "&nbsp;";
    document.body.appendChild(div);
    cachedWidth = div.offsetWidth - div.scrollWidth;
    document.body.removeChild(div);
  }

  return cachedWidth;
}