import { Keys } from '@progress/kendo-react-common';
/**
 * @hidden
 */

var navigate = function navigate(focusedIndex, keyCode, altKey, total) {
  if (altKey) {
    return focusedIndex;
  }

  switch (keyCode) {
    case Keys.enter:
    case Keys.space:
    case Keys.esc:
      return -1;

    case Keys.up:
    case Keys.left:
      return Math.max(0, focusedIndex - 1);

    case Keys.down:
    case Keys.right:
      return Math.min(total - 1, focusedIndex + 1);

    default:
      return focusedIndex;
  }
};

export default navigate;