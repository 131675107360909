var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

var __rest = this && this.__rest || function (s, e) {
  var t = {};

  for (var p in s) {
    if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  }

  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0) t[p[i]] = s[p[i]];
  }
  return t;
};

import * as React from 'react';
/**
 * The `CalendarHeaderTitle` component is internally used for rendering the title of the current calendar view. Also used as a custom `headerTitle` for the [Calendar]({% slug api_dateinputs_calendarprops %}#toc-headertitle) and the [MultiViewCalendar]({% slug api_dateinputs_multiviewcalendarprops %}#toc-headertitle) components.
 *
 * * [Customizing the title for the current Calendar view]({% slug custom_rendering_calendar %}#toc-cells-inside-the-view)
 * * [Customizing the title for the current MultiViewCalendar view]({% slug custom_rendering_multiviewcalendar %}#toc-cells-inside-the-view)
 */

var CalendarHeaderTitle =
/** @class */
function (_super) {
  __extends(CalendarHeaderTitle, _super);

  function CalendarHeaderTitle() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  /**
   * @return
   * Returns a `<span />` element with the [`value`]({% slug api_dateinputs_calendarheadertitleprops %}#toc-value) of the title as a child.
   */


  CalendarHeaderTitle.prototype.render = function () {
    var _a = this.props,
        view = _a.view,
        props = __rest(_a, ["view"]);

    return React.createElement("span", __assign({}, props), this.props.children);
  };

  return CalendarHeaderTitle;
}(React.PureComponent);

export { CalendarHeaderTitle };