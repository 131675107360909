var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

import * as React from 'react';
/**
 * The `CalendarWeekCell` component is internally used for rendering the week cells inside the `month` view of the Calendar. Also used as a [custom week cell for the Calendar]({% slug api_dateinputs_calendarprops %}#toc-weekcell) and the [MultiViewCalendar]({% slug api_dateinputs_multiviewcalendarprops %}#toc-weekcell) components.
 *
 * * [Customizing week-column cells in the Calendar]({% slug custom_rendering_calendar %}#toc-cells-inside-the-week-column)
 * * [Customizing week-column cells in the MultiViewCalendar]({% slug custom_rendering_multiviewcalendar %}#toc-cells-inside-the-week-column)
 */

var CalendarWeekCell =
/** @class */
function (_super) {
  __extends(CalendarWeekCell, _super);

  function CalendarWeekCell() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  /**
   * @return
   * Returns a `<td />` element with the [`value`]({% slug api_dateinputs_calendarweekcellprops %}#toc-value) as a child.
   */


  CalendarWeekCell.prototype.render = function () {
    return React.createElement("td", __assign({}, this.props), this.props.children);
  };

  return CalendarWeekCell;
}(React.Component);

export { CalendarWeekCell };