var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

import * as React from 'react';
import ToolbarItem from './ToolbarItem';
/**
 * Represents the KendoReact Toolbar Separator component.
 */

var ToolbarSeparator =
/** @class */
function (_super) {
  __extends(ToolbarSeparator, _super);

  function ToolbarSeparator() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  /**
   * @hidden
   */


  ToolbarSeparator.prototype.render = function () {
    return React.createElement(ToolbarItem, {
      className: "k-separator"
    });
  };

  return ToolbarSeparator;
}(React.PureComponent);

export default ToolbarSeparator;