'use strict';

module.exports = locate;

function locate(value, fromIndex) {
  var link = value.indexOf('[', fromIndex);
  var image = value.indexOf('![', fromIndex);

  if (image === -1) {
    return link;
  }
  /* Link can never be `-1` if an image is found, so we don’t need
   * to check for that :) */


  return link < image ? link : image;
}