import windowViewport from './window-viewport';

var boundingOffset = function boundingOffset(element) {
  if (!element.getBoundingClientRect) {
    var viewport = windowViewport(element);
    return {
      bottom: viewport.height,
      left: 0,
      right: viewport.width,
      top: 0
    };
  }

  var ref = element.getBoundingClientRect();
  var bottom = ref.bottom;
  var left = ref.left;
  var right = ref.right;
  var top = ref.top;
  return {
    bottom: bottom,
    left: left,
    right: right,
    top: top
  };
};

export default boundingOffset;