var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

import * as React from 'react';
import * as PropTypes from 'prop-types';
import { registerForLocalization, provideLocalizationService } from '@progress/kendo-react-intl';
import { getDate } from '@progress/kendo-date-math';
import { classNames } from '@progress/kendo-react-common';
import { CalendarHeaderTitle } from './CalendarHeaderTitle';
import { messages, today } from '../../messages';
import { getToday, isInRange, dateInRange, MAX_DATE } from '../../utils';
import { MIN_DATE } from '../../defaults';
/**
 * @hidden
 */

var Header =
/** @class */
function (_super) {
  __extends(Header, _super);

  function Header() {
    var _this = _super !== null && _super.apply(this, arguments) || this;

    _this.getTitle = function () {
      if (!_this.props.currentDate) {
        return '';
      }

      var take = _this.rangeLength - 1;

      var title = _this.props.service.title(_this.props.currentDate);

      var nextDate = _this.props.service.addToDate(_this.props.currentDate, take);

      if (take < 1 || !_this.props.service.isInRange(nextDate, _this.min, _this.max)) {
        return title;
      }

      return title + " - " + _this.props.service.title(nextDate);
    };

    _this.handleTitleClick = function (event) {
      if (!_this.navigate) {
        return;
      }

      _this.props.bus.moveUp(_this.props.activeView, event);
    };

    _this.handleTodayClick = function (event) {
      if (!_this.todayIsInRange) {
        return;
      }

      _this.props.bus.moveToBottom(_this.props.activeView);

      var onTodayClick = _this.props.onTodayClick;

      if (onTodayClick) {
        var args = {
          syntheticEvent: event,
          nativeEvent: event.nativeEvent,
          value: dateInRange(getToday(), _this.min, _this.max),
          target: _this
        };
        onTodayClick.call(undefined, args);
      }
    };

    return _this;
  }

  Object.defineProperty(Header.prototype, "min", {
    get: function get() {
      return this.props.min !== undefined ? this.props.min : Header.defaultProps.min;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(Header.prototype, "max", {
    get: function get() {
      return this.props.max !== undefined ? this.props.max : Header.defaultProps.max;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(Header.prototype, "rangeLength", {
    get: function get() {
      return this.props.rangeLength !== undefined ? this.props.rangeLength : Header.defaultProps.rangeLength;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(Header.prototype, "navigate", {
    get: function get() {
      return this.props.bus.canMoveUp(this.props.activeView);
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(Header.prototype, "todayIsInRange", {
    get: function get() {
      return isInRange(getToday(), getDate(this.min), getDate(this.max));
    },
    enumerable: true,
    configurable: true
  });

  Header.prototype.render = function () {
    this.localization = provideLocalizationService(this);
    var todayMessage = this.localization.toLanguageString(today, messages[today]);
    var title = this.getTitle();
    var titleClassNames = classNames('k-button k-bare k-title', {
      'k-state-disabled': !this.navigate
    });
    var todayClassNames = classNames('k-today', {
      'k-state-disabled': !this.todayIsInRange
    });
    var headerTitleProps = {
      children: title,
      value: title,
      view: this.props.activeView,
      className: titleClassNames,
      onClick: this.handleTitleClick
    };
    var headerTitle = this.props.headerTitle ? React.createElement(this.props.headerTitle, __assign({}, headerTitleProps), title) : React.createElement(CalendarHeaderTitle, __assign({}, headerTitleProps), title);
    return React.createElement("div", {
      className: "k-calendar-header"
    }, headerTitle, React.createElement("span", {
      className: todayClassNames,
      onClick: this.handleTodayClick
    }, todayMessage));
  };

  Header.propTypes = {
    activeView: PropTypes.number.isRequired,
    currentDate: PropTypes.instanceOf(Date).isRequired,
    max: PropTypes.instanceOf(Date).isRequired,
    min: PropTypes.instanceOf(Date).isRequired,
    onTodayClick: PropTypes.func,
    rangeLength: PropTypes.number
  };
  Header.defaultProps = {
    rangeLength: 1,
    min: MIN_DATE,
    max: MAX_DATE
  };
  return Header;
}(React.Component);

export { Header };
registerForLocalization(Header);