// Line shaped progress bar
var Shape = require('./shape');

var utils = require('./utils');

var Line = function Line(container, options) {
  this._pathTemplate = 'M 0,{center} L 100,{center}';
  Shape.apply(this, arguments);
};

Line.prototype = new Shape();
Line.prototype.constructor = Line;

Line.prototype._initializeSvg = function _initializeSvg(svg, opts) {
  svg.setAttribute('viewBox', '0 0 100 ' + opts.strokeWidth);
  svg.setAttribute('preserveAspectRatio', 'none');
};

Line.prototype._pathString = function _pathString(opts) {
  return utils.render(this._pathTemplate, {
    center: opts.strokeWidth / 2
  });
};

Line.prototype._trailString = function _trailString(opts) {
  return this._pathString(opts);
};

module.exports = Line;