'use strict';

var xtend = require('xtend');

var inherits = require('inherits');

module.exports = unherit; // Create a custom constructor which can be modified without affecting the
// original class.

function unherit(Super) {
  var result;
  var key;
  var value;
  inherits(Of, Super);
  inherits(From, Of); // Clone values.

  result = Of.prototype;

  for (key in result) {
    value = result[key];

    if (value && typeof value === 'object') {
      result[key] = 'concat' in value ? value.concat() : xtend(value);
    }
  }

  return Of; // Constructor accepting a single argument, which itself is an `arguments`
  // object.

  function From(parameters) {
    return Super.apply(this, parameters);
  } // Constructor accepting variadic arguments.


  function Of() {
    if (!(this instanceof Of)) {
      return new From(arguments);
    }

    return Super.apply(this, arguments);
  }
}