import { cloneDate, addDays } from '@progress/kendo-date-math';
import { TIME_PART } from './models/TimePart';
import { MIDNIGHT_DATE, setTime } from '../utils';

var setter = function setter(method) {
  return function (date, value) {
    var clone = cloneDate(date);
    clone[method](value);
    return clone;
  };
};

var defaultGetters = [{
  type: TIME_PART.hour,
  getter: function getter(value) {
    return value.getHours();
  }
}, {
  type: TIME_PART.minute,
  getter: function getter(value) {
    return value.getMinutes();
  }
}, {
  type: TIME_PART.second,
  getter: function getter(value) {
    return value.getSeconds();
  }
}, {
  type: TIME_PART.millisecond,
  getter: function getter(value) {
    return value.getMilliseconds();
  }
}];

var left = function left(getter) {
  return function (origin, _) {
    return getter(origin);
  };
};

var right = function right(getter) {
  return function (_, candidate) {
    return getter(candidate);
  };
};

var convertToObject = function convertToObject(parts) {
  return parts.reduce(function (obj, p) {
    obj[p.type] = p.type;
    return obj;
  }, {});
};

var getterByPart = function getterByPart(parts) {
  return function (g) {
    return parts[g.type] ? right(g.getter) : left(g.getter);
  };
};

var gettersFactory = function gettersFactory(getters) {
  return function (parts) {
    return getters.map(getterByPart(convertToObject(parts)));
  };
};

var snapValue = function snapValue(getter, step, min, type) {
  return function (date) {
    var value = getter(date);
    var minValue = getter(min);

    if (type === 'hour') {
      return value - (value - minValue) % step;
    }

    if (date.getTime() <= min.getTime() && value !== 0 && value <= minValue) {
      return Math.ceil(value / step) * step;
    }

    return value - value % step;
  };
};

var snappersFactory = function snappersFactory(getters) {
  return function (steps, min) {
    return getters.map(function (g) {
      var step = Math.floor(steps[g.type]);
      return step ? snapValue(g.getter, step, min, g.type) : g.getter;
    });
  };
};
/**
 * @hidden
 */


export var generateGetters = gettersFactory(defaultGetters);
/**
 * @hidden
 */

export var generateSnappers = snappersFactory(defaultGetters);
/**
 * @hidden
 */

export var valueMerger = function valueMerger(getters) {
  return function (origin, candidate) {
    origin.setHours.apply(origin, getters.map(function (g) {
      return g(origin, candidate);
    }));
    return origin;
  };
};
/**
 * @hidden
 */

export var snapTime = function snapTime(snappers) {
  return function (candidate) {
    var date = cloneDate(candidate);
    date.setHours.apply(date, snappers.map(function (s) {
      return s(date);
    }));
    return date;
  };
};
/**
 * @hidden
 */

export var setHours = setter('setHours');
/**
 * @hidden
 */

export var setMinutes = setter('setMinutes');
/**
 * @hidden
 */

export var setSeconds = setter('setSeconds');
/**
 * @hidden
 */

export var getNow = function getNow() {
  return new Date();
};
/**
 * @hidden
 */

export var range = function range(start, end, step) {
  if (step === void 0) {
    step = 1;
  }

  var result = [];

  for (var i = start; i < end; i = i + step) {
    result.push(i);
  }

  return result;
};

var normalizeTimes = function normalizeTimes(candidate, min, max) {
  return {
    candidateValue: setTime(MIDNIGHT_DATE, candidate),
    maxValue: addDays(setTime(MIDNIGHT_DATE, max), min.getHours() < max.getHours() ? 0 : 1),
    minValue: setTime(MIDNIGHT_DATE, min)
  };
};
/**
 * @hidden
 */


export var timeInRange = function timeInRange(candidate, min, max) {
  if (!candidate || !min || !max) {
    return candidate;
  }

  var _a = normalizeTimes(candidate, min, max),
      candidateValue = _a.candidateValue,
      minValue = _a.minValue,
      maxValue = _a.maxValue;

  if (candidateValue < minValue) {
    return setTime(candidate, min);
  }

  if (candidateValue > maxValue) {
    return setTime(candidate, max);
  }

  return candidate;
};
/**
 * @hidden
 */

export var isInTimeRange = function isInTimeRange(candidate, min, max) {
  if (!candidate || !min || !max) {
    return true;
  }

  var _a = normalizeTimes(candidate, min, max),
      candidateValue = _a.candidateValue,
      minValue = _a.minValue,
      maxValue = _a.maxValue;

  return minValue <= candidateValue && candidateValue <= maxValue;
};
/**
 * @hidden
 */

export var isInRange = function isInRange(candidate, min, max) {
  if (candidate === null) {
    return true;
  }

  var _a = normalizeTimes(candidate, min, max),
      candidateValue = _a.candidateValue,
      minValue = _a.minValue,
      maxValue = _a.maxValue;

  return minValue <= candidateValue && candidateValue <= maxValue;
};
/**
 * @hidden
 */

export var isSmallerThanMin = function isSmallerThanMin(val, min) {
  if (val === null || min === null) {
    return false;
  }

  var normalizedValue = setTime(MIDNIGHT_DATE, val);
  var normalizedMin = setTime(MIDNIGHT_DATE, min);
  return normalizedValue.getTime() < normalizedMin.getHours();
};
/**
 * @hidden
 */

export var isBiggerThanMax = function isBiggerThanMax(val, max) {
  if (val === null || max === null) {
    return false;
  }

  var normalizedValue = setTime(MIDNIGHT_DATE, val);
  var normalizedMax = setTime(MIDNIGHT_DATE, max);
  return normalizedMax.getTime() < normalizedValue.getHours();
};