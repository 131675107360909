module.exports = function chain() {
  var len = arguments.length;
  var args = [];

  for (var i = 0; i < len; i++) {
    args[i] = arguments[i];
  }

  args = args.filter(function (fn) {
    return fn != null;
  });
  if (args.length === 0) return undefined;
  if (args.length === 1) return args[0];
  return args.reduce(function (current, next) {
    return function chainedFunction() {
      current.apply(this, arguments);
      next.apply(this, arguments);
    };
  });
};