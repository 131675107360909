'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Thumbs = exports.Carousel = undefined;

var _Carousel = require('./components/Carousel');

var _Carousel2 = _interopRequireDefault(_Carousel);

var _Thumbs = require('./components/Thumbs');

var _Thumbs2 = _interopRequireDefault(_Thumbs);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

exports.Carousel = _Carousel2.default;
exports.Thumbs = _Thumbs2.default;