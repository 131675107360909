import { parents, siblingContainer } from '@progress/kendo-popup-common';
/**
 * @hidden
 */

export var eitherRect = function eitherRect(rect, offset) {
  if (!rect) {
    return {
      height: 0,
      left: offset.left,
      top: offset.top,
      width: 0
    };
  }

  return rect;
};
/**
 * @hidden
 */

export var replaceOffset = function replaceOffset(rect, offset) {
  if (!offset) {
    return rect;
  }

  var result = {
    height: rect.height,
    left: offset.left,
    top: offset.top,
    width: rect.width
  };
  return result;
};
/**
 * @hidden
 */

export var removeStackingOffset = function removeStackingOffset(rect, stackingOffset) {
  if (!stackingOffset) {
    return rect;
  }

  var result = {
    height: rect.height,
    left: rect.left - stackingOffset.left,
    top: rect.top - stackingOffset.top,
    width: rect.width
  };
  return result;
};
/**
 * @hidden
 */

export var isDifferentOffset = function isDifferentOffset(oldOffset, newOffset) {
  var oldLeft = oldOffset.left,
      oldTop = oldOffset.top;
  var newLeft = newOffset.left,
      newTop = newOffset.top;
  return Math.abs(oldLeft - newLeft) >= 1 || Math.abs(oldTop - newTop) >= 1;
};
/**
 * @hidden
 */

export var isDocumentAvailable = function isDocumentAvailable() {
  return typeof document !== 'undefined' && !!document.body;
};
/**
 * @hidden
 */

export var isWindowAvailable = function isWindowAvailable() {
  return typeof window !== 'undefined';
};
/**
 * @hidden
 */

export var hasBoundingRect = function hasBoundingRect(elem) {
  return !!elem.getBoundingClientRect;
};
/**
 * @hidden
 */

export var OVERFLOW_REGEXP = /auto|scroll/;
/**
 * @hidden
 */

var overflowStyle = function overflowStyle(element) {
  var styles = window.getComputedStyle(element);
  return "" + styles.overflow + styles.overflowX + styles.overflowY;
};
/**
 * @hidden
 */


export var scrollableParents = function scrollableParents(element) {
  var parentElements = [];

  if (!isDocumentAvailable() || !isWindowAvailable()) {
    return parentElements;
  }

  var parent = element.parentElement;

  while (parent) {
    if (OVERFLOW_REGEXP.test(overflowStyle(parent))) {
      parentElements.push(parent);
    }

    parent = parent.parentElement;
  }

  parentElements.push(window);
  return parentElements;
};
/**
 * @hidden
 */

export var FRAME_DURATION = 1000 / 60; // 1000ms divided by 60fps

/**
 * @hidden
 */

export var hasRelativeStackingContext = function hasRelativeStackingContext() {
  if (!isDocumentAvailable()) {
    return false;
  }

  var top = 10;
  var parent = document.createElement('div');
  parent.style.transform = 'matrix(10, 0, 0, 10, 0, 0)';
  parent.innerHTML = "<div style=\"position: fixed; top: " + top + "px;\">child</div>";
  document.body.appendChild(parent);
  var isDifferent = parent.children[0].getBoundingClientRect().top !== top;
  document.body.removeChild(parent);
  return isDifferent;
};
/**
 * @hidden
 */

export var HAS_RELATIVE_STACKING_CONTEXT = hasRelativeStackingContext();
/**
 * @hidden
 */

export var zIndex = function zIndex(anchor, container) {
  if (!anchor || !isDocumentAvailable() || !isWindowAvailable()) {
    return null;
  }

  var sibling = siblingContainer(anchor, container);

  if (!sibling) {
    return null;
  }

  var result = [anchor].concat(parents(anchor, sibling)).reduce(function (index, p) {
    var zIndexStyle = p.style.zIndex || window.getComputedStyle(p).zIndex;
    var current = parseInt(zIndexStyle, 10);
    return current > index ? current : index;
  }, 0);
  return result ? result + 1 : null;
};
/**
 * @hidden
 */

export var CollisionType = {
  fit: 'fit',
  flip: 'flip'
};
/**
 * @hidden
 */

export var AlignPoint = {
  left: 'left',
  center: 'center',
  right: 'right',
  bottom: 'bottom',
  top: 'top'
};
/**
 * @hidden
 */

export var throttle = function throttle(func, wait, options) {
  if (options === void 0) {
    options = {};
  }

  var timeout, context, args, result;
  var previous = 0;
  options = options || {};

  var later = function later() {
    previous = options.leading === false ? 0 : new Date().getTime();
    timeout = null;
    result = func.apply(context, args);

    if (!timeout) {
      context = args = null;
    }
  };

  var throttled = function throttled() {
    var now = new Date().getTime();

    if (!previous && options.leading === false) {
      previous = now;
    }

    var remaining = wait - (now - previous);
    context = this;
    args = arguments;

    if (remaining <= 0 || remaining > wait) {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }

      previous = now;
      result = func.apply(context, args);

      if (!timeout) {
        context = args = null;
      }
    } else if (!timeout && options.trailing !== false) {
      timeout = setTimeout(later, remaining);
    }

    return result;
  };

  return throttled;
};