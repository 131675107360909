import { domContainerFactory as containerFactory, isDocumentAvailable } from '../../utils';
/**
 * @hidden
 */

var DOMService =
/** @class */
function () {
  function DOMService() {
    this.didCalculate = false;
  }

  DOMService.prototype.ensureHeights = function () {
    if (this.timeListHeight !== undefined) {
      return;
    }

    this.calculateHeights();
  };

  DOMService.prototype.calculateHeights = function (container) {
    if (!isDocumentAvailable()) {
      return;
    }

    var div = containerFactory('div');
    var ul = containerFactory('ul');
    var li = containerFactory('li');

    var listItem = function listItem() {
      return li('<span>02</span>', 'k-item');
    };

    var list = function list() {
      return ul([listItem()], 'k-reset');
    };

    var scrollable = function scrollable() {
      return div([list()], 'k-time-container k-content k-scrollable');
    };

    var timeListWrapper = function timeListWrapper() {
      return div([div([scrollable()], 'k-time-list')], 'k-time-list-wrapper', {
        left: '-10000px',
        position: 'absolute'
      });
    };

    var timeWrapper = timeListWrapper();
    var listContainer = container && container.querySelector('.k-time-container');
    var hostContainer = listContainer || document.body;
    var wrapper = hostContainer.appendChild(timeWrapper);
    this.timeListHeight = wrapper.querySelector('.k-scrollable').offsetHeight;
    this.itemHeight = wrapper.querySelector('li').offsetHeight;
    hostContainer.removeChild(wrapper);
    this.didCalculate = true;
  };

  return DOMService;
}();

export { DOMService };