import * as PropTypes from 'prop-types';
import { IntlService } from './Intl/IntlService';
import { LocalizationService } from './Localization/LocalizationService'; // tslint:disable:max-line-length

/**
 * Provides an internationalization service. When the passed component is a direct or indirect child of `IntlProvider`, the returned service uses the locale of the provider. Otherwise, uses `en` as a default locale. To handle locale changes, call the method on each `render`.
 *
 * @param componentClass - The React component class that will use the internationalization service.
 */

export function provideIntlService(component) {
  if (!component && process.env.NODE_ENV !== 'production') {
    throw "Passed component - " + component + " is invalid.";
  }

  var intlServiceFromContext = component.context && component.context.kendoIntlService;
  return intlServiceFromContext || new IntlService('en');
}
/**
 * Provides a localization service. When the passed component is a direct or indirect child of `LocalizationProvider`, the returned service uses the language of the provider. To handle locale changes, call the method on each `render`.
 *
 * @param componentClass - The React component class that will use the internationalization service.
 */

export function provideLocalizationService(component) {
  if (!component && process.env.NODE_ENV !== 'production') {
    throw "Passed component - " + component + " is invalid.";
  }

  var localizationServiceFromContext = component.context && component.context.kendoLocalizationService;
  return localizationServiceFromContext || new LocalizationService();
}
/**
 * A method which registers a component class or a functional stateless component for internationalization. When a component of that type is a direct or indirect child of `IntlProvider`, the locale of the provider is used. Otherwise, uses `en` as a default locale.
 *
 * @param component - The React component class that will use the internationalization methods.
 */

export function registerForIntl(component) {
  var ctx = {
    kendoIntlService: PropTypes.object
  };

  if (component.contextTypes) {
    Object.assign(component.contextTypes, ctx);
  } else {
    component.contextTypes = ctx;
  }
}
/**
 * A method which registers a component class or a stateless functional component for localization. When a component of that type is a direct or indirect child of `LocalizationProvider`, the language of the provider is used.
 *
 * @param component - The React component class that will use the internationalization methods.
 */

export function registerForLocalization(component) {
  var ctx = {
    kendoLocalizationService: PropTypes.object
  };

  if (component.contextTypes) {
    Object.assign(component.contextTypes, ctx);
  } else {
    component.contextTypes = ctx;
  }
} // tslint:enable:max-line-length