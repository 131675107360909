var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var _a, _b;

import * as React from 'react';
import * as PropTypes from 'prop-types';
import { cloneDate } from '@progress/kendo-date-math';
import { Keys, noop } from '@progress/kendo-react-common';
import { provideIntlService, registerForIntl } from '@progress/kendo-react-intl';
import { Virtualization } from '../virtualization/Virtualization';
import { TIME_PART } from './models';
import { SecondsService, MinutesService, HoursService, DayPeriodService, DOMService } from './services';
import { MAX_TIME, MIDNIGHT_DATE } from '../utils';
var SCROLL_THRESHOLD = 2; // < 2px threshold

var SNAP_THRESHOLD = 0.05; // % of the item height

var SKIP = 0;
var getters = (_a = {}, _a[Keys.end] = function (data, _) {
  return data[data.length - 1];
}, _a[Keys.home] = function (data, _) {
  return data[0];
}, _a[Keys.up] = function (data, index) {
  return data[index - 1];
}, _a[Keys.down] = function (data, index) {
  return data[index + 1];
}, _a);
var services = (_b = {}, _b[TIME_PART.dayperiod] = DayPeriodService, _b[TIME_PART.hour] = HoursService, _b[TIME_PART.minute] = MinutesService, _b[TIME_PART.second] = SecondsService, _b);
/**
 * @hidden
 */

var TimeList =
/** @class */
function (_super) {
  __extends(TimeList, _super);

  function TimeList(props) {
    var _this = _super.call(this, props) || this;

    _this.animateToIndex = false;

    _this.focus = function (args) {
      Promise.resolve().then(function () {
        if (!_this.element) {
          return;
        }

        _this.element.focus(args);
      });
    };

    _this.itemOffset = function (scrollTop) {
      if (!_this.virtualization) {
        return -1;
      }

      var valueIndex = _this.service.selectedIndex(_this.props.value);

      var activeIndex = _this.virtualization.activeIndex();

      var offset = _this.virtualization.itemOffset(activeIndex);

      var distance = Math.abs(Math.ceil(scrollTop) - offset);

      if (valueIndex === activeIndex && distance < SCROLL_THRESHOLD) {
        return offset;
      }

      var scrollUp = valueIndex > activeIndex;
      var moveToNext = scrollUp && distance >= _this.bottomThreshold || !scrollUp && distance > _this.topThreshold;
      return moveToNext ? _this.virtualization.itemOffset(activeIndex + 1) : offset;
    };

    _this.calculateHeights = function () {
      if (!_this.dom.didCalculate) {
        return;
      }

      _this.itemHeight = _this.dom.itemHeight;
      _this.listHeight = _this.dom.timeListHeight;
      _this.topOffset = (_this.listHeight - _this.itemHeight) / 2;
      _this.bottomOffset = _this.listHeight - _this.itemHeight;
      _this.topThreshold = _this.itemHeight * SNAP_THRESHOLD;
      _this.bottomThreshold = _this.itemHeight * (1 - SNAP_THRESHOLD);
    };

    _this.configureServices = function (_a) {
      var _b = _a === void 0 ? _this.props : _a,
          min = _b.min,
          max = _b.max,
          value = _b.value;

      var _c = _this.service.limitRange(min || _this.min, max || _this.max, value || _this.props.value),
          newMin = _c[0],
          newMax = _c[1];

      _this.service.configure(_this.serviceSettings({
        min: newMin,
        max: newMax
      }));
    };

    _this.serviceSettings = function (settings) {
      var defaults = {
        boundRange: _this.props.boundRange || TimeList.defaultProps.boundRange,
        insertUndividedMax: false,
        min: cloneDate(_this.min),
        max: cloneDate(_this.max),
        part: _this.props.part,
        step: _this.step
      };
      var result = Object.assign({}, defaults, settings);
      result.boundRange = result.part.type !== 'hour' || _this.props.boundRange || TimeList.defaultProps.boundRange;
      return result;
    };

    _this.handleScrollAction = function (_a) {
      var target = _a.target,
          animationInProgress = _a.animationInProgress;

      if (!_this.virtualization) {
        return;
      }

      if (target && !animationInProgress) {
        _this.animateToIndex = false;

        var index = _this.virtualization.itemIndex(_this.itemOffset(target.scrollTop));

        var dataItem = _this.service.data(_this.props.value)[index];

        _this.handleChange(dataItem);
      }
    };

    _this.handleFocus = function (event) {
      var onFocus = _this.props.onFocus;

      if (onFocus) {
        onFocus.call(undefined, event);
      }
    };

    _this.handleBlur = function (event) {
      var onBlur = _this.props.onBlur;

      if (onBlur) {
        onBlur.call(undefined, event);
      }
    };

    _this.handleMouseOver = function () {
      if (!_this._element) {
        return;
      }

      if (document && document.activeElement !== _this._element) {
        _this._element.focus({
          preventScroll: true
        });
      }
    };

    _this.handleKeyDown = function (event) {
      var keyCode = event.keyCode;

      if (keyCode === Keys.down || keyCode === Keys.up || keyCode === Keys.end || keyCode === Keys.home) {
        event.preventDefault();
      }

      var getter = getters[event.keyCode] || noop;
      var dataItem = getter(_this.service.data(_this.props.value), _this.service.selectedIndex(_this.props.value));

      if (dataItem) {
        _this.handleChange(dataItem);
      }
    };

    _this.handleChange = function (dataItem) {
      var candidate = _this.service.apply(_this.props.value, dataItem.value);

      if (_this.props.value.getTime() === candidate.getTime()) {
        return;
      }

      _this.setState({
        value: candidate
      });

      var onChange = _this.props.onChange;

      if (onChange) {
        onChange.call(undefined, candidate);
      }
    };

    _this.dom = new DOMService();
    return _this;
  }

  Object.defineProperty(TimeList.prototype, "element", {
    get: function get() {
      return this._element;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(TimeList.prototype, "animate", {
    get: function get() {
      return Boolean(this.props.smoothScroll && this.animateToIndex);
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(TimeList.prototype, "min", {
    get: function get() {
      return this.props.min || TimeList.defaultProps.min;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(TimeList.prototype, "max", {
    get: function get() {
      return this.props.max || TimeList.defaultProps.max;
    },
    enumerable: true,
    configurable: true
  });
  Object.defineProperty(TimeList.prototype, "step", {
    get: function get() {
      return this.props.step !== undefined && this.props.step !== 0 ? Math.floor(this.props.step) : TimeList.defaultProps.step;
    },
    enumerable: true,
    configurable: true
  });
  /**
   * @hidden
   */

  TimeList.prototype.componentDidMount = function () {
    var _this = this; // Async calculation of height to avoid animation cancellation


    Promise.resolve().then(function () {
      if (!_this._element) {
        return;
      }

      _this.dom.calculateHeights(_this._element);

      _this.forceUpdate();
    });
  };
  /**
   * @hidden
   */


  TimeList.prototype.componentDidUpdate = function () {
    if (!this.virtualization) {
      return;
    }

    var index = this.service.selectedIndex(this.props.value);
    this.virtualization[this.animate ? 'animateToIndex' : 'scrollToIndex'](index);
    this.animateToIndex = true;
  };
  /**
   * @hidden
   */


  TimeList.prototype.render = function () {
    var _this = this;

    if (!this.props.part.type || !services[this.props.part.type]) {
      return;
    }

    this.calculateHeights();
    this.intl = provideIntlService(this);
    this.service = new services[this.props.part.type](this.intl);
    this.configureServices();
    var data = this.service.data(this.props.value);
    var transform = 'translateY(' + this.topOffset + 'px)';
    var total = this.service.total(this.props.value);
    var list = React.createElement("ul", {
      style: {
        transform: transform,
        msTransform: transform
      },
      className: "k-reset"
    }, data.map(function (item, idx) {
      return React.createElement("li", {
        key: idx,
        className: "k-item",
        onClick: function onClick() {
          _this.handleChange(item);
        }
      }, React.createElement("span", null, item.text));
    }));
    return React.createElement("div", {
      className: "k-time-list",
      id: String(this.props.id || ''),
      tabIndex: this.props.disabled ? -1 : 0,
      ref: function ref(el) {
        _this._element = el;
      },
      onKeyDown: this.handleKeyDown,
      onFocus: this.handleFocus,
      onBlur: this.handleBlur,
      onMouseOver: this.handleMouseOver
    }, this.dom.didCalculate ? React.createElement(Virtualization, {
      bottomOffset: this.bottomOffset,
      children: list,
      className: "k-time-container",
      itemHeight: this.itemHeight,
      maxScrollDifference: this.listHeight,
      onScrollAction: this.handleScrollAction,
      ref: function ref(el) {
        _this.virtualization = el;
      },
      role: "presentation",
      skip: SKIP,
      tabIndex: -1,
      take: total,
      topOffset: this.topOffset,
      total: total
    }) : React.createElement("div", {
      className: "k-time-container"
    }, list));
  };

  TimeList.propTypes = {
    id: PropTypes.number,
    max: PropTypes.instanceOf(Date),
    min: PropTypes.instanceOf(Date),
    part: function part(props, propName, componentName) {
      var prop = props[propName];

      if (!prop || !services[prop.type]) {
        throw new Error("\n                    Invalid prop '" + propName + "' supplied to " + componentName + ".\n                    Supported part types are hour|minute|second|dayperiod.\n                ");
      }
    },
    step: function step(props, propName, componentName) {
      var prop = props[propName];

      if (prop !== undefined && prop <= 0) {
        throw new Error("\n                    Invalid prop '" + propName + "' supplied to " + componentName + ".\n                    " + propName + " cannot be less than 1.\n                ");
      }
    },
    value: PropTypes.instanceOf(Date),
    smoothScroll: PropTypes.bool
  };
  TimeList.defaultProps = {
    boundRange: false,
    max: MAX_TIME,
    min: MIDNIGHT_DATE,
    step: 1,
    smoothScroll: true
  };
  return TimeList;
}(React.Component);

export { TimeList };
registerForIntl(TimeList);