import cloneDeep from 'lodash/cloneDeep';
import { toPlainObject, freezeSys } from 'contentful-sdk-core';
/**
 * @memberof Entities
 * @typedef Asset
 * @prop {Entities.Sys} sys - Standard system metadata with additional entry specific properties
 * @prop {string=} sys.locale - If present, indicates the locale which this asset uses
 * @prop {Object} fields - Object with content for each field
 * @prop {string} fields.title - Title for this asset
 * @prop {string} fields.description - Description for this asset
 * @prop {Object} fields.file - File object for this asset
 * @prop {string} fields.file.fileName - Name for the file
 * @prop {string} fields.file.contentType - Mime type for the file
 * @prop {string} fields.file.url - Url where the file is available at.
 * @prop {Object} fields.file.details - Details for the file, depending on file type (example: image size in bytes, etc)
 * @prop {function(): Object} toPlainObject() - Returns this Asset as a plain JS object
 */

/**
 * @private
 * @param {Object} data - Raw asset data
 * @return {Asset} Wrapped asset data
 */

export function wrapAsset(data) {
  return freezeSys(toPlainObject(cloneDeep(data)));
}
/**
 * @memberof Entities
 * @typedef AssetCollection
 * @prop {number} total
 * @prop {number} skip
 * @prop {number} limit
 * @prop {Array<Entities.Asset>} items
 * @prop {function(): Object} toPlainObject() - Returns this Asset collection as a plain JS object
 */

/**
 * @private
 * @param {Object} data - Raw asset collection data
 * @return {AssetCollection} Wrapped asset collection data
 */

export function wrapAssetCollection(data) {
  return freezeSys(toPlainObject(cloneDeep(data)));
}