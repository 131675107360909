/**
 * @hidden
 */
var Mask =
/** @class */
function () {
  function Mask() {
    this.symbols = '';
  }

  return Mask;
}();

export { Mask };