import { filterProps, createError } from '../utils';
var PLURAL_FORMAT_OPTIONS = ['localeMatcher', 'type'];
export function formatPlural(_ref, getPluralRules, value) {
  var locale = _ref.locale,
      onError = _ref.onError;
  var options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};

  if (!Intl.PluralRules) {
    onError(createError("Intl.PluralRules is not available in this environment.\nTry polyfilling it using \"@formatjs/intl-pluralrules\"\n"));
  }

  var filteredOptions = filterProps(options, PLURAL_FORMAT_OPTIONS);

  try {
    return getPluralRules(locale, filteredOptions).select(value);
  } catch (e) {
    onError(createError('Error formatting plural.', e));
  }

  return 'other';
}