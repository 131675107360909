import { eitherRect, removeStackingOffset } from '../util';
/**
 * @hidden
 */

var AlignService =
/** @class */
function () {
  function AlignService(_dom) {
    this._dom = _dom;
  }

  AlignService.prototype.alignElement = function (settings) {
    var anchor = settings.anchor,
        element = settings.element,
        anchorAlign = settings.anchorAlign,
        elementAlign = settings.elementAlign,
        offset = settings.offset;
    var fixedMode = !this._dom.hasOffsetParent(element);
    var anchorRect = fixedMode ? this.absoluteRect(anchor, element, offset) : this.relativeRect(anchor, element, offset);
    return this._dom.align({
      anchorAlign: anchorAlign,
      anchorRect: anchorRect,
      elementAlign: elementAlign,
      elementRect: this._dom.offset(element)
    });
  };

  AlignService.prototype.absoluteRect = function (anchor, element, offset) {
    var dom = this._dom;
    var rect = eitherRect(dom.offset(anchor), offset);
    var stackingOffset = dom.stackingElementOffset(element);
    var removedOffset = removeStackingOffset(rect, stackingOffset);
    var stackingScroll = dom.stackingElementScroll(element);
    var withScroll = dom.addScroll(removedOffset, stackingScroll);
    var scrollPosition = this.elementScrollPosition(anchor, element);
    var result = dom.removeScroll(withScroll, scrollPosition);
    result.left += window.scrollX || window.pageXOffset;
    result.top += window.scrollY || window.pageYOffset;
    return result;
  };

  AlignService.prototype.elementScrollPosition = function (anchor, element) {
    return anchor ? {
      x: 0,
      y: 0
    } : this._dom.scrollPosition(element);
  };

  AlignService.prototype.relativeRect = function (anchor, element, offset) {
    return eitherRect(this._dom.position(anchor, element), offset);
  };

  return AlignService;
}();

export { AlignService };