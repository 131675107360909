function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Router } from 'react-router';
import { LOCATION_CHANGE } from './reducer';

var ConnectedRouter = function (_Component) {
  _inherits(ConnectedRouter, _Component);

  function ConnectedRouter() {
    var _temp, _this, _ret;

    _classCallCheck(this, ConnectedRouter);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, _Component.call.apply(_Component, [this].concat(args))), _this), _this.handleLocationChange = function (location) {
      _this.store.dispatch({
        type: LOCATION_CHANGE,
        payload: location
      });
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  ConnectedRouter.prototype.componentWillMount = function componentWillMount() {
    var _props = this.props,
        propsStore = _props.store,
        history = _props.history,
        isSSR = _props.isSSR;
    this.store = propsStore || this.context.store;
    this.handleLocationChange(history.location);
    if (!isSSR) this.unsubscribeFromHistory = history.listen(this.handleLocationChange);
  };

  ConnectedRouter.prototype.componentWillUnmount = function componentWillUnmount() {
    if (this.unsubscribeFromHistory) this.unsubscribeFromHistory();
  };

  ConnectedRouter.prototype.render = function render() {
    return React.createElement(Router, this.props);
  };

  return ConnectedRouter;
}(Component);

ConnectedRouter.propTypes = {
  store: PropTypes.object,
  history: PropTypes.object.isRequired,
  children: PropTypes.node,
  isSSR: PropTypes.bool
};
ConnectedRouter.contextTypes = {
  store: PropTypes.object
};
export default ConnectedRouter;