var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

var __rest = this && this.__rest || function (s, e) {
  var t = {};

  for (var p in s) {
    if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  }

  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0) t[p[i]] = s[p[i]];
  }
  return t;
};

import * as React from 'react';
import * as PropTypes from 'prop-types';
import { Animation } from './Animation'; // tslint:enable:max-line-length

var Slide =
/** @class */
function (_super) {
  __extends(Slide, _super);

  function Slide() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  /**
   * @hidden
   */


  Slide.prototype.render = function () {
    var _a = this.props,
        direction = _a.direction,
        children = _a.children,
        other = __rest(_a, ["direction", "children"]);

    var animationProps = {
      transitionName: "slide-" + direction
    };
    return React.createElement(Animation, __assign({}, animationProps, other), children);
  };
  /**
   * @hidden
   */


  Slide.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    childFactory: PropTypes.any,
    className: PropTypes.string,
    direction: PropTypes.oneOf(['up', 'down', 'left', 'right']),
    component: PropTypes.string,
    id: PropTypes.string,
    style: PropTypes.any
  };
  /**
   * @hidden
   */

  Slide.defaultProps = {
    appear: false,
    enter: true,
    exit: true,
    transitionEnterDuration: 300,
    transitionExitDuration: 300,
    direction: 'down'
  };
  return Slide;
}(React.Component);

export { Slide };