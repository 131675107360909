import { eitherRect, replaceOffset } from '../util';
/**
 * @hidden
 */

var PositionService =
/** @class */
function () {
  function PositionService(_dom) {
    this._dom = _dom;
  }

  PositionService.prototype.positionElement = function (settings) {
    var anchor = settings.anchor,
        currentLocation = settings.currentLocation,
        element = settings.element,
        anchorAlign = settings.anchorAlign,
        elementAlign = settings.elementAlign,
        collisions = settings.collisions;
    var dom = this._dom;
    var viewPort = settings.viewPort || dom.stackingElementViewPort(element) || dom.windowViewPort(element);
    var anchorRect = eitherRect(dom.offset(anchor), currentLocation);
    var initialElementRect = replaceOffset(dom.staticOffset(element), currentLocation);
    var elementRect = this.elementRect(element, initialElementRect);
    var result = dom.restrictToView({
      anchorAlign: anchorAlign,
      anchorRect: anchorRect,
      collisions: collisions,
      elementAlign: elementAlign,
      elementRect: elementRect,
      viewPort: viewPort
    });
    return {
      flipped: result.flipped,
      offset: dom.addOffset(initialElementRect, result.offset)
    };
  };

  PositionService.prototype.elementRect = function (element, rect) {
    return this._dom.removeScroll(rect, this._dom.scrollPosition(element));
  };

  return PositionService;
}();

export { PositionService };