/**
 * @hidden
 */
var styles = {
  button: 'k-button',
  'bare': 'k-bare',
  'flat': 'k-flat',
  'outline': 'k-outline',
  'primary': 'k-primary',
  'state-active': 'k-state-active',
  'button-icon': 'k-button-icon',
  'button-icontext': 'k-button-icontext',
  'state-disabled': 'k-state-disabled',
  'group-start': 'k-group-start',
  'group-end': 'k-group-end',
  'button-group': 'k-button-group',
  'button-group-stretched': 'k-button-group-stretched',
  'ltr': 'k-ltr',
  'rtl': 'k-rtl'
};
/**
 * @hidden
 */

export default {
  styles: styles
};