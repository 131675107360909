var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

var __rest = this && this.__rest || function (s, e) {
  var t = {};

  for (var p in s) {
    if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  }

  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0) t[p[i]] = s[p[i]];
  }
  return t;
};

import * as React from 'react';
import { classNames } from '@progress/kendo-react-common';
/**
 * The `CalendarNavigationItem` component is internally used for rendering the items in the side navigation of the Calendar. Also used as a custom navigation item for the [Calendar]({% slug api_dateinputs_calendarprops %}#toc-navigationitem) ([see example]({% slug custom_rendering_calendar %}#toc-items-in-the-side-navigation)).
 */

var CalendarNavigationItem =
/** @class */
function (_super) {
  __extends(CalendarNavigationItem, _super);

  function CalendarNavigationItem() {
    var _this = _super !== null && _super.apply(this, arguments) || this;

    _this.handleClick = function (event) {
      var _a = _this.props,
          onClick = _a.onClick,
          value = _a.value;

      if (onClick) {
        onClick.call(undefined, value, event);
      }
    };

    return _this;
  }
  /**
   * @return
   * Returns a `<li />` element with a `<span />` inside. The text inside represents the [`value`]({% slug api_dateinputs_calendarnavigationitemprops %}#toc-value) of the `navigationItem`.
   */


  CalendarNavigationItem.prototype.render = function () {
    var _a = this.props,
        isRangeStart = _a.isRangeStart,
        value = _a.value,
        text = _a.text,
        other = __rest(_a, ["isRangeStart", "value", "text"]);

    return React.createElement("li", __assign({}, other, {
      onClick: this.handleClick
    }), React.createElement("span", {
      className: classNames({
        'k-calendar-navigation-marker': isRangeStart
      })
    }, this.props.children));
  };

  return CalendarNavigationItem;
}(React.Component);

export { CalendarNavigationItem };