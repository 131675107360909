import { getLocaleInfo } from './info';
import { EMPTY } from '../common/constants';

function lowerArray(arr) {
  var result = [];

  for (var idx = 0; idx < arr.length; idx++) {
    result.push(arr[idx].toLowerCase());
  }

  return result;
}

function lowerObject(obj) {
  var result = {};

  for (var field in obj) {
    result[field] = obj[field].toLowerCase();
  }

  return result;
}

function cloneLower(obj) {
  var result = Array.isArray(obj) ? lowerArray(obj) : lowerObject(obj);
  return result;
}

export default function dateFormatNames(locale, options) {
  var type = options.type;
  var nameType = options.nameType;
  var standAlone = options.standAlone;
  var lower = options.lower;
  var info = getLocaleInfo(locale);
  var formatType = standAlone ? "stand-alone" : "format";
  var lowerNameType = (lower ? "lower-" : EMPTY) + nameType;
  var formatNames = info.calendar[type][formatType];
  var result = formatNames[lowerNameType];

  if (!result && lower) {
    result = formatNames[lowerNameType] = cloneLower(formatNames[nameType]);
  }

  return result;
}