var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

import * as React from 'react';
import { classNames } from '@progress/kendo-react-common';
/**
 * @hidden
 */

var ButtonItem =
/** @class */
function (_super) {
  __extends(ButtonItem, _super);

  function ButtonItem() {
    var _this = _super !== null && _super.apply(this, arguments) || this;

    _this.onClick = function (event) {
      return _this.props.onClick(event, _this.props.index);
    };

    return _this;
  }

  ButtonItem.prototype.render = function () {
    var _a = this.props,
        dataItem = _a.dataItem,
        focused = _a.focused,
        id = _a.id,
        onDown = _a.onDown;
    return React.createElement("li", {
      id: id,
      className: classNames('k-item', {
        'k-state-focused': focused,
        'k-state-selected': dataItem.selected,
        'k-state-disabled': dataItem.disabled
      }),
      onClick: this.onClick,
      onMouseDown: onDown,
      onPointerDown: onDown,
      role: "menuItem",
      "aria-disabled": dataItem.disabled || undefined
    }, this.renderContent());
  };

  ButtonItem.prototype.renderContent = function () {
    var _a = this.props,
        dataItem = _a.dataItem,
        textField = _a.textField,
        index = _a.index;
    var Render = this.props.dataItem.render || this.props.itemRender;
    var text = dataItem.text !== undefined ? dataItem.text : textField ? dataItem[textField] : dataItem;
    var iconClass = dataItem.icon ? "k-icon k-i-" + dataItem.icon : dataItem.iconClass;
    return Render && React.createElement(Render, {
      item: dataItem,
      itemIndex: index
    }) || [iconClass && React.createElement("span", {
      className: iconClass,
      role: "presentation",
      key: "icon"
    }), dataItem.imageUrl && React.createElement("img", {
      className: "k-image",
      alt: "",
      src: dataItem.imageUrl,
      role: "presentation",
      key: "image"
    }), text];
  };

  return ButtonItem;
}(React.Component);

export default ButtonItem;