export var DECIMAL = "decimal";
export var CURRENCY = "currency";
export var ACCOUNTING = "accounting";
export var PERCENT = "percent";
export var SCIENTIFIC = "scientific";
export var CURRENCY_PLACEHOLDER = "$";
export var PERCENT_PLACEHOLDER = "%";
export var NUMBER_PLACEHOLDER = "n";
export var LIST_SEPARATOR = ";";
export var GROUP_SEPARATOR = ",";
export var POINT = ".";
export var EMPTY = "";
export var DEFAULT_LOCALE = "en";