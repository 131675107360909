var _a;
/**
 * @hidden
 */


export var prevView = 'multiviewcalendar.prevView';
/**
 * @hidden
 */

export var nextView = 'multiviewcalendar.nextView';
/**
 * @hidden
 */

export var increaseValue = 'dateinput.increment';
/**
 * @hidden
 */

export var decreaseValue = 'dateinput.decrement';
/**
 * @hidden
 */

export var today = 'calendar.today';
/**
 * @hidden
 */

export var toggleCalendar = 'datepicker.toggleCalendar';
/**
 * @hidden
 */

export var swapStartEnd = 'daterangepicker.swapStartEnd';
/**
 * @hidden
 */

export var start = 'daterangepicker.start';
/**
 * @hidden
 */

export var end = 'daterangepicker.end';
/**
 * @hidden
 */

export var toggleDateTimeSelector = 'datetimepicker.toggleDateTimeSelector';
/**
 * @hidden
 */

export var now = 'timepicker.now';
/**
 * @hidden
 */

export var selectNow = 'timepicker.selectNow';
/**
 * @hidden
 */

export var timePickerCancel = 'timepicker.cancel';
/**
 * @hidden
 */

export var timePickerSet = 'timepicker.set';
/**
 * @hidden
 */

export var toggleTimeSelector = 'timepicker.toggleTimeSelector';
/**
 * @hidden
 */

export var toggleClock = 'timepicker.toggleClock';
/**
 * @hidden
 */

export var date = 'datetimepicker.date';
/**
 * @hidden
 */

export var time = 'datetimepicker.time';
/**
 * @hidden
 */

export var dateTimePickerCancel = 'datetimepicker.cancel';
/**
 * @hidden
 */

export var dateTimePickerSet = 'datetimepicker.set';
/**
 * @hidden
 */

export var messages = (_a = {}, _a[today] = 'TODAY', _a[now] = 'NOW', _a[timePickerSet] = 'Set', _a[timePickerCancel] = 'Cancel', _a[date] = 'Date', _a[time] = 'Time', _a[dateTimePickerCancel] = 'Cancel', _a[dateTimePickerSet] = 'Set', _a[start] = 'Start', _a[end] = 'End', _a[selectNow] = 'Select Now', _a[toggleTimeSelector] = 'Toggle TimeSelector', _a[toggleClock] = 'Toggle Clock', _a[increaseValue] = 'Increase value', _a[decreaseValue] = 'Decrease value', _a[toggleCalendar] = 'Toggle calendar', _a[prevView] = 'Navigate to previous view', _a[nextView] = 'Navigate to next view', _a[swapStartEnd] = 'Swap start and end values', _a[toggleDateTimeSelector] = 'Toggle date-time selector', _a);