import { messages } from './messages';
/**
 * A service which provides localization methods.
 */

var LocalizationService =
/** @class */
function () {
  function LocalizationService(language) {
    this.language = language;

    if (language === '' && process.env.NODE_ENV !== 'production') {
      throw 'Language should not be an empty string';
    }
  } // tslint:disable:max-line-length

  /**
   * Provides a string based on a key for the current language. When no string for the current language is available under this key, the `defaultValue` is returned.
   *
   * @param key - The key which identifies the string for the current language.
   * @param defaultValue - The default value which will be returned when no string
   * for the current language is available under the key.
   * @return - The string for the current language.
   */
  // tslint:enable:max-line-length


  LocalizationService.prototype.toLanguageString = function (key, defaultValue) {
    if (this.language && messages[this.language] && messages[this.language].hasOwnProperty(key)) {
      return messages[this.language][key];
    } else {
      return defaultValue;
    }
  };

  return LocalizationService;
}();

export { LocalizationService };