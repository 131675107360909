var _slicedToArray = function () {
  function sliceIterator(arr, i) {
    var _arr = [];
    var _n = true;
    var _d = false;
    var _e = undefined;

    try {
      for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
        _arr.push(_s.value);

        if (i && _arr.length === i) break;
      }
    } catch (err) {
      _d = true;
      _e = err;
    } finally {
      try {
        if (!_n && _i["return"]) _i["return"]();
      } finally {
        if (_d) throw _e;
      }
    }

    return _arr;
  }

  return function (arr, i) {
    if (Array.isArray(arr)) {
      return arr;
    } else if (Symbol.iterator in Object(arr)) {
      return sliceIterator(arr, i);
    } else {
      throw new TypeError("Invalid attempt to destructure non-iterable instance");
    }
  };
}();

var _extends = Object.assign || function (target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i];

    for (var key in source) {
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        target[key] = source[key];
      }
    }
  }

  return target;
};

var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}

import React, { Component } from 'react';
import classnames from 'classnames'; //

import _ from './utils';
import Lifecycle from './lifecycle';
import Methods from './methods';
import defaultProps from './defaultProps';
import propTypes from './propTypes';
export var ReactTableDefaults = defaultProps;

var ReactTable = function (_Methods) {
  _inherits(ReactTable, _Methods);

  function ReactTable(props) {
    _classCallCheck(this, ReactTable);

    var _this = _possibleConstructorReturn(this, (ReactTable.__proto__ || Object.getPrototypeOf(ReactTable)).call(this));

    _this.getResolvedState = _this.getResolvedState.bind(_this);
    _this.getDataModel = _this.getDataModel.bind(_this);
    _this.getSortedData = _this.getSortedData.bind(_this);
    _this.fireFetchData = _this.fireFetchData.bind(_this);
    _this.getPropOrState = _this.getPropOrState.bind(_this);
    _this.getStateOrProp = _this.getStateOrProp.bind(_this);
    _this.filterData = _this.filterData.bind(_this);
    _this.sortData = _this.sortData.bind(_this);
    _this.getMinRows = _this.getMinRows.bind(_this);
    _this.onPageChange = _this.onPageChange.bind(_this);
    _this.onPageSizeChange = _this.onPageSizeChange.bind(_this);
    _this.sortColumn = _this.sortColumn.bind(_this);
    _this.filterColumn = _this.filterColumn.bind(_this);
    _this.resizeColumnStart = _this.resizeColumnStart.bind(_this);
    _this.resizeColumnEnd = _this.resizeColumnEnd.bind(_this);
    _this.resizeColumnMoving = _this.resizeColumnMoving.bind(_this);
    _this.state = {
      page: 0,
      pageSize: props.defaultPageSize,
      sorted: props.defaultSorted,
      expanded: props.defaultExpanded,
      filtered: props.defaultFiltered,
      resized: props.defaultResized,
      currentlyResizing: false,
      skipNextSort: false
    };
    return _this;
  }

  _createClass(ReactTable, [{
    key: 'render',
    value: function render() {
      var _this2 = this;

      var resolvedState = this.getResolvedState();
      var children = resolvedState.children,
          className = resolvedState.className,
          style = resolvedState.style,
          getProps = resolvedState.getProps,
          getTableProps = resolvedState.getTableProps,
          getTheadGroupProps = resolvedState.getTheadGroupProps,
          getTheadGroupTrProps = resolvedState.getTheadGroupTrProps,
          getTheadGroupThProps = resolvedState.getTheadGroupThProps,
          getTheadProps = resolvedState.getTheadProps,
          getTheadTrProps = resolvedState.getTheadTrProps,
          getTheadThProps = resolvedState.getTheadThProps,
          getTheadFilterProps = resolvedState.getTheadFilterProps,
          getTheadFilterTrProps = resolvedState.getTheadFilterTrProps,
          getTheadFilterThProps = resolvedState.getTheadFilterThProps,
          getTbodyProps = resolvedState.getTbodyProps,
          getTrGroupProps = resolvedState.getTrGroupProps,
          getTrProps = resolvedState.getTrProps,
          getTdProps = resolvedState.getTdProps,
          getTfootProps = resolvedState.getTfootProps,
          getTfootTrProps = resolvedState.getTfootTrProps,
          getTfootTdProps = resolvedState.getTfootTdProps,
          getPaginationProps = resolvedState.getPaginationProps,
          getLoadingProps = resolvedState.getLoadingProps,
          getNoDataProps = resolvedState.getNoDataProps,
          getResizerProps = resolvedState.getResizerProps,
          showPagination = resolvedState.showPagination,
          showPaginationTop = resolvedState.showPaginationTop,
          showPaginationBottom = resolvedState.showPaginationBottom,
          manual = resolvedState.manual,
          loadingText = resolvedState.loadingText,
          noDataText = resolvedState.noDataText,
          sortable = resolvedState.sortable,
          multiSort = resolvedState.multiSort,
          resizable = resolvedState.resizable,
          filterable = resolvedState.filterable,
          pivotIDKey = resolvedState.pivotIDKey,
          pivotValKey = resolvedState.pivotValKey,
          pivotBy = resolvedState.pivotBy,
          subRowsKey = resolvedState.subRowsKey,
          aggregatedKey = resolvedState.aggregatedKey,
          originalKey = resolvedState.originalKey,
          indexKey = resolvedState.indexKey,
          groupedByPivotKey = resolvedState.groupedByPivotKey,
          loading = resolvedState.loading,
          pageSize = resolvedState.pageSize,
          page = resolvedState.page,
          sorted = resolvedState.sorted,
          filtered = resolvedState.filtered,
          resized = resolvedState.resized,
          expanded = resolvedState.expanded,
          pages = resolvedState.pages,
          onExpandedChange = resolvedState.onExpandedChange,
          TableComponent = resolvedState.TableComponent,
          TheadComponent = resolvedState.TheadComponent,
          TbodyComponent = resolvedState.TbodyComponent,
          TrGroupComponent = resolvedState.TrGroupComponent,
          TrComponent = resolvedState.TrComponent,
          ThComponent = resolvedState.ThComponent,
          TdComponent = resolvedState.TdComponent,
          TfootComponent = resolvedState.TfootComponent,
          PaginationComponent = resolvedState.PaginationComponent,
          LoadingComponent = resolvedState.LoadingComponent,
          SubComponent = resolvedState.SubComponent,
          NoDataComponent = resolvedState.NoDataComponent,
          ResizerComponent = resolvedState.ResizerComponent,
          ExpanderComponent = resolvedState.ExpanderComponent,
          PivotValueComponent = resolvedState.PivotValueComponent,
          PivotComponent = resolvedState.PivotComponent,
          AggregatedComponent = resolvedState.AggregatedComponent,
          FilterComponent = resolvedState.FilterComponent,
          PadRowComponent = resolvedState.PadRowComponent,
          resolvedData = resolvedState.resolvedData,
          allVisibleColumns = resolvedState.allVisibleColumns,
          headerGroups = resolvedState.headerGroups,
          hasHeaderGroups = resolvedState.hasHeaderGroups,
          sortedData = resolvedState.sortedData,
          currentlyResizing = resolvedState.currentlyResizing; // Pagination

      var startRow = pageSize * page;
      var endRow = startRow + pageSize;
      var pageRows = manual ? resolvedData : sortedData.slice(startRow, endRow);
      var minRows = this.getMinRows();

      var padRows = _.range(Math.max(minRows - pageRows.length, 0));

      var hasColumnFooter = allVisibleColumns.some(function (d) {
        return d.Footer;
      });
      var hasFilters = filterable || allVisibleColumns.some(function (d) {
        return d.filterable;
      });

      var recurseRowsViewIndex = function recurseRowsViewIndex(rows) {
        var path = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
        var index = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : -1;
        return [rows.map(function (row, i) {
          index += 1;

          var rowWithViewIndex = _extends({}, row, {
            _viewIndex: index
          });

          var newPath = path.concat([i]);

          if (rowWithViewIndex[subRowsKey] && _.get(expanded, newPath)) {
            var _recurseRowsViewIndex = recurseRowsViewIndex(rowWithViewIndex[subRowsKey], newPath, index);

            var _recurseRowsViewIndex2 = _slicedToArray(_recurseRowsViewIndex, 2);

            rowWithViewIndex[subRowsKey] = _recurseRowsViewIndex2[0];
            index = _recurseRowsViewIndex2[1];
          }

          return rowWithViewIndex;
        }), index];
      };

      var _recurseRowsViewIndex3 = recurseRowsViewIndex(pageRows);

      var _recurseRowsViewIndex4 = _slicedToArray(_recurseRowsViewIndex3, 1);

      pageRows = _recurseRowsViewIndex4[0];
      var canPrevious = page > 0;
      var canNext = page + 1 < pages;

      var rowMinWidth = _.sum(allVisibleColumns.map(function (d) {
        var resizedColumn = resized.find(function (x) {
          return x.id === d.id;
        }) || {};
        return _.getFirstDefined(resizedColumn.value, d.width, d.minWidth);
      }));

      var rowIndex = -1;

      var finalState = _extends({}, resolvedState, {
        startRow: startRow,
        endRow: endRow,
        pageRows: pageRows,
        minRows: minRows,
        padRows: padRows,
        hasColumnFooter: hasColumnFooter,
        canPrevious: canPrevious,
        canNext: canNext,
        rowMinWidth: rowMinWidth
      });

      var rootProps = _.splitProps(getProps(finalState, undefined, undefined, this));

      var tableProps = _.splitProps(getTableProps(finalState, undefined, undefined, this));

      var tBodyProps = _.splitProps(getTbodyProps(finalState, undefined, undefined, this));

      var loadingProps = getLoadingProps(finalState, undefined, undefined, this);
      var noDataProps = getNoDataProps(finalState, undefined, undefined, this); // Visual Components

      var makeHeaderGroup = function makeHeaderGroup(column, i) {
        var resizedValue = function resizedValue(col) {
          return (resized.find(function (x) {
            return x.id === col.id;
          }) || {}).value;
        };

        var flex = _.sum(column.columns.map(function (col) {
          return col.width || resizedValue(col) ? 0 : col.minWidth;
        }));

        var width = _.sum(column.columns.map(function (col) {
          return _.getFirstDefined(resizedValue(col), col.width, col.minWidth);
        }));

        var maxWidth = _.sum(column.columns.map(function (col) {
          return _.getFirstDefined(resizedValue(col), col.width, col.maxWidth);
        }));

        var theadGroupThProps = _.splitProps(getTheadGroupThProps(finalState, undefined, column, _this2));

        var columnHeaderProps = _.splitProps(column.getHeaderProps(finalState, undefined, column, _this2));

        var classes = [column.headerClassName, theadGroupThProps.className, columnHeaderProps.className];

        var styles = _extends({}, column.headerStyle, theadGroupThProps.style, columnHeaderProps.style);

        var rest = _extends({}, theadGroupThProps.rest, columnHeaderProps.rest);

        var flexStyles = {
          flex: flex + ' 0 auto',
          width: _.asPx(width),
          maxWidth: _.asPx(maxWidth)
        };
        return React.createElement(ThComponent, _extends({
          key: i + '-' + column.id,
          className: classnames(classes),
          style: _extends({}, styles, flexStyles)
        }, rest), _.normalizeComponent(column.Header, {
          data: sortedData,
          column: column
        }));
      };

      var makeHeaderGroups = function makeHeaderGroups() {
        var theadGroupProps = _.splitProps(getTheadGroupProps(finalState, undefined, undefined, _this2));

        var theadGroupTrProps = _.splitProps(getTheadGroupTrProps(finalState, undefined, undefined, _this2));

        return React.createElement(TheadComponent, _extends({
          className: classnames('-headerGroups', theadGroupProps.className),
          style: _extends({}, theadGroupProps.style, {
            minWidth: rowMinWidth + 'px'
          })
        }, theadGroupProps.rest), React.createElement(TrComponent, _extends({
          className: theadGroupTrProps.className,
          style: theadGroupTrProps.style
        }, theadGroupTrProps.rest), headerGroups.map(makeHeaderGroup)));
      };

      var makeHeader = function makeHeader(column, i) {
        var resizedCol = resized.find(function (x) {
          return x.id === column.id;
        }) || {};
        var sort = sorted.find(function (d) {
          return d.id === column.id;
        });
        var show = typeof column.show === 'function' ? column.show() : column.show;

        var width = _.getFirstDefined(resizedCol.value, column.width, column.minWidth);

        var maxWidth = _.getFirstDefined(resizedCol.value, column.width, column.maxWidth);

        var theadThProps = _.splitProps(getTheadThProps(finalState, undefined, column, _this2));

        var columnHeaderProps = _.splitProps(column.getHeaderProps(finalState, undefined, column, _this2));

        var classes = [column.headerClassName, theadThProps.className, columnHeaderProps.className];

        var styles = _extends({}, column.headerStyle, theadThProps.style, columnHeaderProps.style);

        var rest = _extends({}, theadThProps.rest, columnHeaderProps.rest);

        var isResizable = _.getFirstDefined(column.resizable, resizable, false);

        var resizer = isResizable ? React.createElement(ResizerComponent, _extends({
          onMouseDown: function onMouseDown(e) {
            return _this2.resizeColumnStart(e, column, false);
          },
          onTouchStart: function onTouchStart(e) {
            return _this2.resizeColumnStart(e, column, true);
          }
        }, getResizerProps('finalState', undefined, column, _this2))) : null;

        var isSortable = _.getFirstDefined(column.sortable, sortable, false);

        return React.createElement(ThComponent, _extends({
          key: i + '-' + column.id,
          className: classnames(classes, isResizable && 'rt-resizable-header', sort ? sort.desc ? '-sort-desc' : '-sort-asc' : '', isSortable && '-cursor-pointer', !show && '-hidden', pivotBy && pivotBy.slice(0, -1).includes(column.id) && 'rt-header-pivot'),
          style: _extends({}, styles, {
            flex: width + ' 0 auto',
            width: _.asPx(width),
            maxWidth: _.asPx(maxWidth)
          }),
          toggleSort: function toggleSort(e) {
            if (isSortable) _this2.sortColumn(column, multiSort ? e.shiftKey : false);
          }
        }, rest), React.createElement('div', {
          className: classnames(isResizable && 'rt-resizable-header-content')
        }, _.normalizeComponent(column.Header, {
          data: sortedData,
          column: column
        })), resizer);
      };

      var makeHeaders = function makeHeaders() {
        var theadProps = _.splitProps(getTheadProps(finalState, undefined, undefined, _this2));

        var theadTrProps = _.splitProps(getTheadTrProps(finalState, undefined, undefined, _this2));

        return React.createElement(TheadComponent, _extends({
          className: classnames('-header', theadProps.className),
          style: _extends({}, theadProps.style, {
            minWidth: rowMinWidth + 'px'
          })
        }, theadProps.rest), React.createElement(TrComponent, _extends({
          className: theadTrProps.className,
          style: theadTrProps.style
        }, theadTrProps.rest), allVisibleColumns.map(makeHeader)));
      };

      var makeFilter = function makeFilter(column, i) {
        var resizedCol = resized.find(function (x) {
          return x.id === column.id;
        }) || {};

        var width = _.getFirstDefined(resizedCol.value, column.width, column.minWidth);

        var maxWidth = _.getFirstDefined(resizedCol.value, column.width, column.maxWidth);

        var theadFilterThProps = _.splitProps(getTheadFilterThProps(finalState, undefined, column, _this2));

        var columnHeaderProps = _.splitProps(column.getHeaderProps(finalState, undefined, column, _this2));

        var classes = [column.headerClassName, theadFilterThProps.className, columnHeaderProps.className];

        var styles = _extends({}, column.headerStyle, theadFilterThProps.style, columnHeaderProps.style);

        var rest = _extends({}, theadFilterThProps.rest, columnHeaderProps.rest);

        var filter = filtered.find(function (filter) {
          return filter.id === column.id;
        });
        var ResolvedFilterComponent = column.Filter || FilterComponent;

        var isFilterable = _.getFirstDefined(column.filterable, filterable, false);

        return React.createElement(ThComponent, _extends({
          key: i + '-' + column.id,
          className: classnames(classes),
          style: _extends({}, styles, {
            flex: width + ' 0 auto',
            width: _.asPx(width),
            maxWidth: _.asPx(maxWidth)
          })
        }, rest), isFilterable ? _.normalizeComponent(ResolvedFilterComponent, {
          column: column,
          filter: filter,
          onChange: function onChange(value) {
            return _this2.filterColumn(column, value);
          }
        }, defaultProps.column.Filter) : null);
      };

      var makeFilters = function makeFilters() {
        var theadFilterProps = _.splitProps(getTheadFilterProps(finalState, undefined, undefined, _this2));

        var theadFilterTrProps = _.splitProps(getTheadFilterTrProps(finalState, undefined, undefined, _this2));

        return React.createElement(TheadComponent, _extends({
          className: classnames('-filters', theadFilterProps.className),
          style: _extends({}, theadFilterProps.style, {
            minWidth: rowMinWidth + 'px'
          })
        }, theadFilterProps.rest), React.createElement(TrComponent, _extends({
          className: theadFilterTrProps.className,
          style: theadFilterTrProps.style
        }, theadFilterTrProps.rest), allVisibleColumns.map(makeFilter)));
      };

      var makePageRow = function makePageRow(row, i) {
        var path = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
        var rowInfo = {
          original: row[originalKey],
          row: row,
          index: row[indexKey],
          viewIndex: rowIndex += 1,
          pageSize: pageSize,
          page: page,
          level: path.length,
          nestingPath: path.concat([i]),
          aggregated: row[aggregatedKey],
          groupedByPivot: row[groupedByPivotKey],
          subRows: row[subRowsKey]
        };

        var isExpanded = _.get(expanded, rowInfo.nestingPath);

        var trGroupProps = getTrGroupProps(finalState, rowInfo, undefined, _this2);

        var trProps = _.splitProps(getTrProps(finalState, rowInfo, undefined, _this2));

        return React.createElement(TrGroupComponent, _extends({
          key: rowInfo.nestingPath.join('_')
        }, trGroupProps), React.createElement(TrComponent, _extends({
          className: classnames(trProps.className, row._viewIndex % 2 ? '-even' : '-odd'),
          style: trProps.style
        }, trProps.rest), allVisibleColumns.map(function (column, i2) {
          var resizedCol = resized.find(function (x) {
            return x.id === column.id;
          }) || {};
          var show = typeof column.show === 'function' ? column.show() : column.show;

          var width = _.getFirstDefined(resizedCol.value, column.width, column.minWidth);

          var maxWidth = _.getFirstDefined(resizedCol.value, column.width, column.maxWidth);

          var tdProps = _.splitProps(getTdProps(finalState, rowInfo, column, _this2));

          var columnProps = _.splitProps(column.getProps(finalState, rowInfo, column, _this2));

          var classes = [tdProps.className, column.className, columnProps.className];

          var styles = _extends({}, tdProps.style, column.style, columnProps.style);

          var cellInfo = _extends({}, rowInfo, {
            isExpanded: isExpanded,
            column: _extends({}, column),
            value: rowInfo.row[column.id],
            pivoted: column.pivoted,
            expander: column.expander,
            resized: resized,
            show: show,
            width: width,
            maxWidth: maxWidth,
            tdProps: tdProps,
            columnProps: columnProps,
            classes: classes,
            styles: styles
          });

          var value = cellInfo.value;
          var useOnExpanderClick = void 0;
          var isBranch = void 0;
          var isPreview = void 0;

          var onExpanderClick = function onExpanderClick(e) {
            var newExpanded = _.clone(expanded);

            if (isExpanded) {
              newExpanded = _.set(newExpanded, cellInfo.nestingPath, false);
            } else {
              newExpanded = _.set(newExpanded, cellInfo.nestingPath, {});
            }

            return _this2.setStateWithData({
              expanded: newExpanded
            }, function () {
              return onExpandedChange && onExpandedChange(newExpanded, cellInfo.nestingPath, e);
            });
          }; // Default to a standard cell


          var resolvedCell = _.normalizeComponent(column.Cell, cellInfo, value); // Resolve Renderers


          var ResolvedAggregatedComponent = column.Aggregated || (!column.aggregate ? AggregatedComponent : column.Cell);
          var ResolvedExpanderComponent = column.Expander || ExpanderComponent;
          var ResolvedPivotValueComponent = column.PivotValue || PivotValueComponent;

          var DefaultResolvedPivotComponent = PivotComponent || function (props) {
            return React.createElement('div', null, React.createElement(ResolvedExpanderComponent, props), React.createElement(ResolvedPivotValueComponent, props));
          };

          var ResolvedPivotComponent = column.Pivot || DefaultResolvedPivotComponent; // Is this cell expandable?

          if (cellInfo.pivoted || cellInfo.expander) {
            // Make it expandable by defualt
            cellInfo.expandable = true;
            useOnExpanderClick = true; // If pivoted, has no subRows, and does not have a subComponent,
            // do not make expandable

            if (cellInfo.pivoted && !cellInfo.subRows && !SubComponent) {
              cellInfo.expandable = false;
            }
          }

          if (cellInfo.pivoted) {
            // Is this column a branch?
            isBranch = rowInfo.row[pivotIDKey] === column.id && cellInfo.subRows; // Should this column be blank?

            isPreview = pivotBy.indexOf(column.id) > pivotBy.indexOf(rowInfo.row[pivotIDKey]) && cellInfo.subRows; // Pivot Cell Render Override

            if (isBranch) {
              // isPivot
              resolvedCell = _.normalizeComponent(ResolvedPivotComponent, _extends({}, cellInfo, {
                value: row[pivotValKey]
              }), row[pivotValKey]);
            } else if (isPreview) {
              // Show the pivot preview
              resolvedCell = _.normalizeComponent(ResolvedAggregatedComponent, cellInfo, value);
            } else {
              resolvedCell = null;
            }
          } else if (cellInfo.aggregated) {
            resolvedCell = _.normalizeComponent(ResolvedAggregatedComponent, cellInfo, value);
          }

          if (cellInfo.expander) {
            resolvedCell = _.normalizeComponent(ResolvedExpanderComponent, cellInfo, row[pivotValKey]);

            if (pivotBy) {
              if (cellInfo.groupedByPivot) {
                resolvedCell = null;
              }

              if (!cellInfo.subRows && !SubComponent) {
                resolvedCell = null;
              }
            }
          }

          var resolvedOnExpanderClick = useOnExpanderClick ? onExpanderClick : function () {}; // If there are multiple onClick events, make sure they don't
          // override eachother. This should maybe be expanded to handle all
          // function attributes

          var interactionProps = {
            onClick: resolvedOnExpanderClick
          };

          if (tdProps.rest.onClick) {
            interactionProps.onClick = function (e) {
              tdProps.rest.onClick(e, function () {
                return resolvedOnExpanderClick(e);
              });
            };
          }

          if (columnProps.rest.onClick) {
            interactionProps.onClick = function (e) {
              columnProps.rest.onClick(e, function () {
                return resolvedOnExpanderClick(e);
              });
            };
          } // Return the cell


          return React.createElement(TdComponent // eslint-disable-next-line react/no-array-index-key
          , _extends({
            key: i2 + '-' + column.id,
            className: classnames(classes, !show && 'hidden', cellInfo.expandable && 'rt-expandable', (isBranch || isPreview) && 'rt-pivot'),
            style: _extends({}, styles, {
              flex: width + ' 0 auto',
              width: _.asPx(width),
              maxWidth: _.asPx(maxWidth)
            })
          }, tdProps.rest, columnProps.rest, interactionProps), resolvedCell);
        })), rowInfo.subRows && isExpanded && rowInfo.subRows.map(function (d, i) {
          return makePageRow(d, i, rowInfo.nestingPath);
        }), SubComponent && !rowInfo.subRows && isExpanded && SubComponent(rowInfo));
      };

      var makePadColumn = function makePadColumn(column, i) {
        var resizedCol = resized.find(function (x) {
          return x.id === column.id;
        }) || {};
        var show = typeof column.show === 'function' ? column.show() : column.show;

        var width = _.getFirstDefined(resizedCol.value, column.width, column.minWidth);

        var flex = width;

        var maxWidth = _.getFirstDefined(resizedCol.value, column.width, column.maxWidth);

        var tdProps = _.splitProps(getTdProps(finalState, undefined, column, _this2));

        var columnProps = _.splitProps(column.getProps(finalState, undefined, column, _this2));

        var classes = [tdProps.className, column.className, columnProps.className];

        var styles = _extends({}, tdProps.style, column.style, columnProps.style);

        return React.createElement(TdComponent, _extends({
          key: i + '-' + column.id,
          className: classnames(classes, !show && 'hidden'),
          style: _extends({}, styles, {
            flex: flex + ' 0 auto',
            width: _.asPx(width),
            maxWidth: _.asPx(maxWidth)
          })
        }, tdProps.rest), _.normalizeComponent(PadRowComponent));
      };

      var makePadRow = function makePadRow(row, i) {
        var trGroupProps = getTrGroupProps(finalState, undefined, undefined, _this2);

        var trProps = _.splitProps(getTrProps(finalState, undefined, undefined, _this2));

        return React.createElement(TrGroupComponent, _extends({
          key: i
        }, trGroupProps), React.createElement(TrComponent, {
          className: classnames('-padRow', (pageRows.length + i) % 2 ? '-even' : '-odd', trProps.className),
          style: trProps.style || {}
        }, allVisibleColumns.map(makePadColumn)));
      };

      var makeColumnFooter = function makeColumnFooter(column, i) {
        var resizedCol = resized.find(function (x) {
          return x.id === column.id;
        }) || {};
        var show = typeof column.show === 'function' ? column.show() : column.show;

        var width = _.getFirstDefined(resizedCol.value, column.width, column.minWidth);

        var maxWidth = _.getFirstDefined(resizedCol.value, column.width, column.maxWidth);

        var tFootTdProps = _.splitProps(getTfootTdProps(finalState, undefined, undefined, _this2));

        var columnProps = _.splitProps(column.getProps(finalState, undefined, column, _this2));

        var columnFooterProps = _.splitProps(column.getFooterProps(finalState, undefined, column, _this2));

        var classes = [tFootTdProps.className, column.className, columnProps.className, columnFooterProps.className];

        var styles = _extends({}, tFootTdProps.style, column.style, columnProps.style, columnFooterProps.style);

        return React.createElement(TdComponent, _extends({
          key: i + '-' + column.id,
          className: classnames(classes, !show && 'hidden'),
          style: _extends({}, styles, {
            flex: width + ' 0 auto',
            width: _.asPx(width),
            maxWidth: _.asPx(maxWidth)
          })
        }, columnProps.rest, tFootTdProps.rest, columnFooterProps.rest), _.normalizeComponent(column.Footer, {
          data: sortedData,
          column: column
        }));
      };

      var makeColumnFooters = function makeColumnFooters() {
        var tFootProps = getTfootProps(finalState, undefined, undefined, _this2);

        var tFootTrProps = _.splitProps(getTfootTrProps(finalState, undefined, undefined, _this2));

        return React.createElement(TfootComponent, _extends({
          className: tFootProps.className,
          style: _extends({}, tFootProps.style, {
            minWidth: rowMinWidth + 'px'
          })
        }, tFootProps.rest), React.createElement(TrComponent, _extends({
          className: classnames(tFootTrProps.className),
          style: tFootTrProps.style
        }, tFootTrProps.rest), allVisibleColumns.map(makeColumnFooter)));
      };

      var makePagination = function makePagination() {
        var paginationProps = _.splitProps(getPaginationProps(finalState, undefined, undefined, _this2));

        return React.createElement(PaginationComponent, _extends({}, resolvedState, {
          pages: pages,
          canPrevious: canPrevious,
          canNext: canNext,
          onPageChange: _this2.onPageChange,
          onPageSizeChange: _this2.onPageSizeChange,
          className: paginationProps.className,
          style: paginationProps.style
        }, paginationProps.rest));
      };

      var makeTable = function makeTable() {
        var pagination = makePagination();
        return React.createElement('div', _extends({
          className: classnames('ReactTable', className, rootProps.className),
          style: _extends({}, style, rootProps.style)
        }, rootProps.rest), showPagination && showPaginationTop ? React.createElement('div', {
          className: 'pagination-top'
        }, pagination) : null, React.createElement(TableComponent, _extends({
          className: classnames(tableProps.className, currentlyResizing ? 'rt-resizing' : ''),
          style: tableProps.style
        }, tableProps.rest), hasHeaderGroups ? makeHeaderGroups() : null, makeHeaders(), hasFilters ? makeFilters() : null, React.createElement(TbodyComponent, _extends({
          className: classnames(tBodyProps.className),
          style: _extends({}, tBodyProps.style, {
            minWidth: rowMinWidth + 'px'
          })
        }, tBodyProps.rest), pageRows.map(function (d, i) {
          return makePageRow(d, i);
        }), padRows.map(makePadRow)), hasColumnFooter ? makeColumnFooters() : null), showPagination && showPaginationBottom ? React.createElement('div', {
          className: 'pagination-bottom'
        }, pagination) : null, !pageRows.length && React.createElement(NoDataComponent, noDataProps, _.normalizeComponent(noDataText)), React.createElement(LoadingComponent, _extends({
          loading: loading,
          loadingText: loadingText
        }, loadingProps)));
      }; // childProps are optionally passed to a function-as-a-child


      return children ? children(finalState, makeTable, this) : makeTable();
    }
  }]);

  return ReactTable;
}(Methods(Lifecycle(Component)));

ReactTable.propTypes = propTypes;
ReactTable.defaultProps = defaultProps;
export default ReactTable;