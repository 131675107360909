var normalize = function normalize(x) {
  return Math.max(x, 0);
};
/**
 * @hidden
 */


var ScrollAction =
/** @class */
function () {
  function ScrollAction(offset) {
    this.offset = offset;
  }

  return ScrollAction;
}();

export { ScrollAction };
/**
 * @hidden
 */

var PageAction =
/** @class */
function () {
  function PageAction(skip) {
    this.skip = skip;
  }

  return PageAction;
}();

export { PageAction };
/**
 * @hidden
 */

var ScrollerService =
/** @class */
function () {
  function ScrollerService(onScrollAction, onPageAction) {
    this.onScrollAction = onScrollAction;
    this.onPageAction = onPageAction;
    this.firstLoaded = 0;
    this.bottomOffset = 0;
    this.topOffset = 0;
  }

  ScrollerService.prototype.create = function (rowHeightService, skip, take, total, topOffset, bottomOffset, direction) {
    if (topOffset === void 0) {
      topOffset = 0;
    }

    if (bottomOffset === void 0) {
      bottomOffset = 0;
    }

    if (direction === void 0) {
      direction = 'vertical';
    }

    this.rowHeightService = rowHeightService;
    this.firstLoaded = skip;
    this.lastLoaded = skip + take;
    this.take = take;
    this.total = total;
    this.lastScrollTop = 0;
    this.topOffset = topOffset;
    this.bottomOffset = bottomOffset;
    this.direction = direction;
    var offsetBufferRows = this.rowsForHeight(topOffset);
    var skipWithOffset = normalize(skip - offsetBufferRows);
    this.onScrollAction(new ScrollAction(this.rowOffset(skipWithOffset)));
    this.onPageAction(new PageAction(skipWithOffset));
  };

  ScrollerService.prototype.onScroll = function (_a) {
    var scrollLeft = _a.scrollLeft,
        scrollTop = _a.scrollTop,
        offsetHeight = _a.offsetHeight,
        offsetWidth = _a.offsetWidth;
    var scrollPosition = this.direction === 'vertical' ? scrollTop : scrollLeft;
    var offsetSize = this.direction === 'vertical' ? offsetHeight : offsetWidth;

    if (this.lastScrollTop === scrollPosition) {
      return;
    }

    var up = this.lastScrollTop >= scrollPosition;
    this.lastScrollTop = scrollPosition;
    var firstItemIndex = this.rowHeightService.index(normalize(scrollPosition - this.topOffset));
    var lastItemIndex = this.rowHeightService.index(normalize(scrollPosition + offsetSize - this.bottomOffset));

    if (!up && lastItemIndex >= this.lastLoaded && this.lastLoaded < this.total) {
      this.firstLoaded = firstItemIndex;
      this.onScrollAction(new ScrollAction(this.rowOffset(firstItemIndex)));
      this.lastLoaded = Math.min(this.firstLoaded + this.take, this.total);
      this.onPageAction(new PageAction(this.firstLoaded));
    }

    if (up && firstItemIndex <= this.firstLoaded) {
      var nonVisibleBuffer = Math.floor(this.take * 0.3);
      this.firstLoaded = normalize(firstItemIndex - nonVisibleBuffer);
      this.onScrollAction(new ScrollAction(this.rowOffset(this.firstLoaded)));
      this.lastLoaded = Math.min(this.firstLoaded + this.take, this.total);
      this.onPageAction(new PageAction(this.firstLoaded));
    }
  };

  ScrollerService.prototype.rowOffset = function (index) {
    return this.rowHeightService.offset(index) + this.topOffset;
  };

  ScrollerService.prototype.rowsForHeight = function (height) {
    return Math.ceil(height / this.rowHeightService.height(0));
  };

  return ScrollerService;
}();

export { ScrollerService };