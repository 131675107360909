export default function (element) {
  var result = [];
  var sibling = element.parentNode.firstElementChild;

  while (sibling) {
    if (sibling !== element) {
      result.push(sibling);
    }

    sibling = sibling.nextElementSibling;
  }

  return result;
}
;