import { cldr } from './info';

function territoryFromName(name, identity) {
  var likelySubtags = cldr.supplemental.likelySubtags;
  var parts = name.split("-");

  if (likelySubtags) {
    var likelyName = likelySubtags[name] || likelySubtags[parts[0]];

    if (likelyName) {
      parts = likelyName.split("-");
    }
  }

  if (identity) {
    for (var idx = parts.length - 1; idx >= 1; idx--) {
      var part = parts[idx];

      if (part === identity.variant || part === identity.script) {
        parts.splice(idx, 1);
      }
    }
  }

  var length = parts.length;

  if (length > 1) {
    var territory = parts[length - 1];
    return territory.toUpperCase();
  }
}

export default function localeTerritory(info) {
  if (info.territory) {
    return info.territory;
  }

  var name = info.name;
  var identity = info.identity;
  var territory;

  if (identity && identity.territory) {
    territory = identity.territory;
  } else {
    territory = territoryFromName(name, identity);
  }

  info.territory = territory;
  return territory;
}