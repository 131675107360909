var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

var __rest = this && this.__rest || function (s, e) {
  var t = {};

  for (var p in s) {
    if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  }

  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0) t[p[i]] = s[p[i]];
  }
  return t;
};

import * as React from 'react';
import * as PropTypes from 'prop-types';
import { Animation } from './Animation';
import { noop } from '@progress/kendo-react-common';
import util from './util';

var Reveal =
/** @class */
function (_super) {
  __extends(Reveal, _super);

  function Reveal() {
    var _this = _super !== null && _super.apply(this, arguments) || this;
    /**
     * @hidden
     */


    _this.state = {
      maxHeight: undefined,
      maxWidth: undefined
    };

    _this.componentWillEnter = function (event) {
      var onEnter = _this.props.onEnter;

      _this.updateContainerDimensions(event.animatedElement, function () {
        if (onEnter) {
          onEnter.call(undefined, event);
        }
      });
    };

    _this.componentIsEntering = function (event) {
      var onEntering = _this.props.onEntering;

      _this.updateContainerDimensions(event.animatedElement, function () {
        if (onEntering) {
          onEntering.call(undefined, event);
        }
      });
    };

    _this.componentWillExit = function (event) {
      var onExit = _this.props.onExit;

      _this.updateContainerDimensions(event.animatedElement, function () {
        if (onExit) {
          onExit.call(undefined, event);
        }
      });
    };

    _this.updateContainerDimensions = function (node, done) {
      if (done === void 0) {
        done = noop;
      }

      var content = node.firstChild;

      if (content) {
        var newHeight = util.outerHeight(content);
        var newWidth = util.outerWidth(content);

        _this.setState({
          maxHeight: newHeight,
          maxWidth: newWidth
        }, done);
      }
    };

    return _this;
  }
  /**
   * @hidden
   */


  Reveal.prototype.render = function () {
    var _a = this.props,
        direction = _a.direction,
        children = _a.children,
        childFactory = _a.childFactory,
        other = __rest(_a, ["direction", "children", "childFactory"]);

    var _b = this.state,
        maxHeight = _b.maxHeight,
        maxWidth = _b.maxWidth;
    var maxOffset;

    if (direction === 'vertical') {
      maxOffset = {
        maxHeight: maxHeight ? maxHeight + "px" : null
      };
    } else {
      maxOffset = {
        maxWidth: maxWidth ? maxWidth + "px" : null
      };
    }

    var animationEnteringStyle = {
      maxHeight: maxOffset.maxHeight,
      maxWidth: maxOffset.maxWidth
    };

    var factory = function factory(child) {
      if (!child.props.in) {
        return React.cloneElement(child, __assign({}, child.props, {
          style: __assign({}, child.props.style, {
            maxHeight: maxOffset.maxHeight,
            maxWidth: maxOffset.maxWidth
          })
        }));
      }

      return child;
    };

    return React.createElement(Animation, __assign({}, other, {
      childFactory: childFactory ? childFactory : factory,
      onEnter: this.componentWillEnter,
      onEntering: this.componentIsEntering,
      onExit: this.componentWillExit,
      animationEnteringStyle: animationEnteringStyle,
      transitionName: "reveal-" + direction
    }), children);
  };
  /**
   * @hidden
   */


  Reveal.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    childFactory: PropTypes.any,
    className: PropTypes.string,
    direction: PropTypes.oneOf(['horizontal', 'vertical']),
    component: PropTypes.string,
    id: PropTypes.string,
    style: PropTypes.any
  };
  /**
   * @hidden
   */

  Reveal.defaultProps = {
    appear: false,
    enter: true,
    exit: true,
    transitionEnterDuration: 300,
    transitionExitDuration: 300,
    direction: 'vertical'
  };
  return Reveal;
}(React.Component);

export { Reveal };