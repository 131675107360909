var parse = require('../parse/index.js');
/**
 * @category Weekday Helpers
 * @summary Get the day of the week of the given date.
 *
 * @description
 * Get the day of the week of the given date.
 *
 * @param {Date|String|Number} date - the given date
 * @returns {Number} the day of week
 *
 * @example
 * // Which day of the week is 29 February 2012?
 * var result = getDay(new Date(2012, 1, 29))
 * //=> 3
 */


function getDay(dirtyDate) {
  var date = parse(dirtyDate);
  var day = date.getDay();
  return day;
}

module.exports = getDay;