export default function groupInteger(number, start, end, options, info) {
  var symbols = info.numbers.symbols;
  var decimalIndex = number.indexOf(symbols.decimal);
  var groupSizes = options.groupSize.slice();
  var groupSize = groupSizes.shift();
  var integerEnd = decimalIndex !== -1 ? decimalIndex : end + 1;
  var integer = number.substring(start, integerEnd);
  var result = number;
  var integerLength = integer.length;

  if (integerLength >= groupSize) {
    var idx = integerLength;
    var parts = [];

    while (idx > -1) {
      var value = integer.substring(idx - groupSize, idx);

      if (value) {
        parts.push(value);
      }

      idx -= groupSize;
      var newGroupSize = groupSizes.shift();
      groupSize = newGroupSize !== undefined ? newGroupSize : groupSize;

      if (groupSize === 0) {
        value = integer.substring(0, idx);

        if (value) {
          parts.push(value);
        }

        break;
      }
    }

    integer = parts.reverse().join(symbols.group);
    result = number.substring(0, start) + integer + number.substring(integerEnd);
  }

  return result;
}